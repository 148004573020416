import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { type Contents } from '@jupyterlab/services'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import { BaseLayout, Modal, Spin, Typography, notification } from '@/components'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { Pagination } from '@/types'
import { select_access_token } from '@/user/user_slice'

import useLogger from '../hooks/useLogger'
import {
  ConfirmOverwriteSharedNotebooksConfig,
  ConfirmOverwriteSharedNotebooksProvider,
  NotebookMatches
} from './ConfirmOverwriteSharedNotebooks'
import {
  JupyterBridgeId,
  JupyterBridgeMessageEvent,
  JupyterBridgeMessageType
} from './constants'
import {
  GET_SHARED_NOTEBOOKS,
  LOAD_SHARED_NOTEBOOK_BY_FILE_NAME,
  SAVE_SHARED_NOTEBOOK
} from './queries/notebooks'

const { Title } = Typography

export const JupyterLite = () => {
  const [modal, modalContextHolder] = Modal.useModal()
  const [notificationApi, notificationContextHolder] =
    notification.useNotification()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const accessToken = useSelector(select_access_token)
  const logger = useLogger()

  const { organization_key, workspace_key } = useParams()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const workspace_ids = useMemo(() => [workspace_id as string], [workspace_id])

  const [searchParams] = useSearchParams()
  const isDebug = searchParams.get('debug') === 'true'
  const jupyterPath = searchParams.get('path') ?? 'byterat/Welcome.ipynb'

  const [notebookMatches, setNotebookMatches] = useState<NotebookMatches>({})
  const [loadedOnce, setLoadedOnce] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [, setIsReady] = useState(false)
  const [paginationModel] = useState<Pagination>({
    pageSize: 100,
    page: 0
  })

  const queryVariables = useMemo(
    () => ({
      organization_id: organization_id as string,
      workspace_ids,
      page: paginationModel.page + 1, // The backend indexes from 1
      page_size: paginationModel.pageSize
    }),
    [organization_id, workspace_ids, paginationModel]
  )

  const { data, loading } = useQuery(GET_SHARED_NOTEBOOKS, {
    fetchPolicy: 'network-only',
    variables: queryVariables,
    skip: !organization_id || !workspace_id,
    onCompleted: () => {
      setLoadedOnce(true)
    }
  })

  const [loadSharedNotebookByFilename] = useLazyQuery(
    LOAD_SHARED_NOTEBOOK_BY_FILE_NAME,
    { fetchPolicy: 'network-only' }
  )

  const [sharedNotebooks, setSharedNotebooks] = useState<
    Record<string, Contents.IModel>
  >({})

  useEffect(() => {
    if (organization_key == null) return
    const sharedNotebooks = data?.get_shared_notebooks?.data
    if (sharedNotebooks != null) {
      setSharedNotebooks(prev => {
        const newSharedNotebooks = sharedNotebooks.reduce(
          (acc, item) => {
            const notebook = JSON.parse(item.ipynb_json)
            notebook.content.metadata = {
              ...notebook.content.metadata,
              $owner: organization_key.toLowerCase()
            }
            acc[item.id] = notebook
            return acc
          },
          {} as Record<string, Contents.IModel>
        )
        return { ...prev, ...newSharedNotebooks }
      })
    }
  }, [data, organization_key])

  const [saveMutation] = useMutation(SAVE_SHARED_NOTEBOOK, {
    refetchQueries: [
      { query: GET_SHARED_NOTEBOOKS, variables: queryVariables }
    ],
    awaitRefetchQueries: true
  })

  const sendMessageToIframe = useCallback(
    (type: JupyterBridgeMessageType, payload?: any) => {
      iframeRef.current?.contentWindow?.postMessage(
        {
          source: JupyterBridgeId,
          type,
          payload
        },
        '*'
      )
    },
    []
  )

  const saveSharedNotebooks = useCallback(
    async (payload: Contents.IModel[]) => {
      if (organization_id == null || workspace_ids == null) return
      // Check if the filename already exists in the database as a shared file
      const storedMatches = await Promise.all(
        payload.map(item =>
          loadSharedNotebookByFilename({
            variables: {
              file_name: item.name,
              workspace_ids
            }
          })
        )
      )

      // Assume confirmed by default, because if there are no existing notebooks, we don't need to ask anything
      let confirmed = true
      // If there are any existing notebooks with the same filename, ask the user if they want to overwrite
      if (storedMatches.some(item => item.data)) {
        const existingNotebooks = storedMatches
          .filter(item => item.data)
          .map(item => item.data?.load_shared_notebook_by_file_name)

        setNotebookMatches(
          existingNotebooks.reduce((acc, existingNotebook) => {
            if (existingNotebook == null) return acc
            const { file_name } = existingNotebook

            const newNotebook = payload.find(
              newNotebook => newNotebook.name === file_name
            )
            if (newNotebook == null) return acc

            acc[file_name] = {
              existingNotebook,
              newNotebook
            }

            return acc
          }, {} as NotebookMatches)
        )
        confirmed = await new Promise<boolean>(resolve => {
          modal.confirm({
            ...ConfirmOverwriteSharedNotebooksConfig,
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            okText: 'Overwrite'
          })
        })
      }

      // If the user doesn't want to overwrite, return
      if (!confirmed) return

      const savePromises = payload.map(item => {
        return saveMutation({
          variables: {
            id: uuidv4(),
            file_name: item.name,
            organization_id,
            workspace_ids,
            ipynb_json: JSON.stringify(item)
          }
        })
      })
      await Promise.all(savePromises)

      notificationApi.success({
        message: (
          <div>
            Notebooks saved successfully:
            {payload.map(item => (
              <div className='font-mono'>{item.name}</div>
            ))}
          </div>
        ),
        description: (
          <div>
            This notebook will be shared with all users who have access to this
            workspace, and will be available in the{' '}
            <span className='font-mono'>shared</span> directory.
          </div>
        )
      })
    },
    [
      loadSharedNotebookByFilename,
      modal,
      notificationApi,
      organization_id,
      saveMutation,
      workspace_ids
    ]
  )

  const renameToSharedExtensionBlocked = useCallback(
    (payload: string) => {
      notificationApi.warning({
        message: 'File extension reserved',
        description: (
          <div>
            The extension <span className='font-mono'>.shared.ipynb</span> is
            reserved for shared notebooks. This file has been renamed to{' '}
            <span className='font-mono'>{payload}</span>.
          </div>
        )
      })
    },
    [notificationApi]
  )

  const onMessageReceived = useCallback(
    (event: JupyterBridgeMessageEvent) => {
      const { source, type, payload } = event.data
      if (source !== JupyterBridgeId) return
      logger.verbose('Message received in the parent:', event.data)

      switch (type) {
        case JupyterBridgeMessageType.Error:
          logger.error('JupyterLite has an error:', payload)
          break
        case JupyterBridgeMessageType.Initialized:
          logger.verbose('JupyterLite is initialized. Setting flag.')
          setIsInitialized(true)
          break
        case JupyterBridgeMessageType.Ready:
          logger.verbose('JupyterLite is ready. Setting flag.')
          setIsReady(true)
          break
        case JupyterBridgeMessageType.Ack:
          logger.verbose('It is an ack message, nothing to do')
          break
        case JupyterBridgeMessageType.Operation:
          logger.verbose('Operation message received:', payload)
          break
        case JupyterBridgeMessageType.SharedExtensionBlocked:
          if (payload != null && typeof payload === 'string') {
            renameToSharedExtensionBlocked(payload)
          }
          break
        case JupyterBridgeMessageType.CopyToClipboard:
          if (payload != null && typeof payload === 'string') {
            const separator = window.location.href.includes('?') ? '&' : '?'
            navigator.clipboard.writeText(
              `${window.location.href}${separator}${payload}`
            )
          }
          break
        case JupyterBridgeMessageType.SaveSharedNotebook:
          if (payload != null && typeof payload !== 'string') {
            saveSharedNotebooks(payload as Contents.IModel[])
          }
          break
        default:
          logger.warn('Unknown message type:', type)
      }

      if (type !== JupyterBridgeMessageType.Ack) {
        sendMessageToIframe(JupyterBridgeMessageType.Ack)
      }
    },
    [
      logger,
      renameToSharedExtensionBlocked,
      saveSharedNotebooks,
      sendMessageToIframe
    ]
  )

  useEffect(() => {
    window.addEventListener('message', onMessageReceived)
    return () => {
      window.removeEventListener('message', onMessageReceived)
    }
  }, [onMessageReceived])

  useEffect(() => {
    if (!isInitialized) return

    sendMessageToIframe(JupyterBridgeMessageType.Identify, {
      organizationKey: organization_key,
      workspaceKey: workspace_key,
      accessToken: accessToken
    })
  }, [
    isInitialized,
    organization_key,
    workspace_key,
    accessToken,
    sendMessageToIframe
  ])

  useEffect(() => {
    if (!isInitialized) return

    logger.verbose('JupyterLite is ready, sending shared notebooks')
    sendMessageToIframe(
      JupyterBridgeMessageType.LoadSharedNotebook,
      Object.values(sharedNotebooks)
    )
  }, [isInitialized, sharedNotebooks, logger, sendMessageToIframe])

  if (accessToken == null) return <Spin />

  return (
    <BaseLayout className='flex flex-col flex-1 gap-y-4 px-0 pt-4'>
      <ConfirmOverwriteSharedNotebooksProvider
        value={{ matches: notebookMatches }}
      >
        <Title level={3} className='!mb-0 px-4'>
          Notebooks
        </Title>
        {modalContextHolder}
        {notificationContextHolder}
        <iframe
          src={`//byterat-redspot.vercel.app/lab/index.html?path=${jupyterPath}${isDebug ? '&debug=true' : ''}`}
          title='byterat jupyter notebook'
          name='redspot'
          className='flex-1'
          ref={iframeRef}
        />
      </ConfirmOverwriteSharedNotebooksProvider>
    </BaseLayout>
  )
}
