import { Typography } from '@/components'
import { BaseLayout } from '@/components/BaseLayout'

import WorkspaceUsersList from '../components/WorkspaceUsersList'

const { Title } = Typography

export const AccountSettings = () => {
  return (
    <BaseLayout className='py-4 flex flex-col gap-y-4'>
      <div>
        <Title level={3}>Workspace Settings</Title>
      </div>
      <WorkspaceUsersList />
    </BaseLayout>
  )
}
