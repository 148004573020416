import { useQuery } from '@apollo/client'
import Title from 'antd/es/typography/Title'
import { useAtom, useSetAtom } from 'jotai'
import { assign, compact, isEmpty, isEqual, isNil } from 'lodash-es'
import { nanoid } from 'nanoid'
import numbro from 'numbro'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

import { Skeleton } from '@/components'
import { emptyObject } from '@/constants'
import {
  Get_Cycle_SummariesQuery,
  Get_Cycler_ObservationsQuery
} from '@/gql_generated/graphql'
import { cn } from '@/utils'

import { use_workspace_and_org_ids } from '../../navigation/hooks/use_workspace_and_org_ids'
import { LOAD_METRIC_TARGET_LIMITS } from '../cell-specifications/queries/load_metric_target_limits'
import { GET_CYCLER_OBSERVATIONS } from '../charts/cycle_observations/queries/get_cycler_observations'
import { GET_CYCLE_SUMMARIES } from '../charts/cycle_summaries/queries/get_cycle_summaries'
import { ChartLegend } from '../charts/legends/ChartLegend'
import { InsightsChartTypes } from '../charts/types'
import { InsightsChartFilters } from '../controls/InsightsChartFilters'
import { apexChartOptionsAtom } from '../jotai/apex.atoms'
import { _getDatasetHasMultipleCycles } from '../jotai/apexHelpers/_getDatasetHasMultipleCycles'
import {
  InsightsChartState,
  chartSelectedAnnotationsAtom,
  chartStatesFamily
} from '../jotai/charts.atoms'

type ObservationResults =
  Get_Cycler_ObservationsQuery['get_cycler_observations']
type SummaryResults = Get_Cycle_SummariesQuery['get_cycle_summaries']

type InsightsChartProps = {
  chartId?: string
  datasets?: any[]
  datasetColumns?: any[]
  datasetIds: string[]
  showFilterControls?: boolean
  title?: ReactNode
  subTitle?: ReactNode
  state?: Partial<InsightsChartState>
  loading?: boolean
  reportMode?: boolean
}

export const InsightsChart = (props: InsightsChartProps) => {
  const {
    chartId,
    datasets,
    datasetColumns,
    datasetIds,
    showFilterControls = true,
    title,
    subTitle,
    state,
    reportMode,
    loading: externalLoading
  } = props
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const chartRef = useRef<ReactApexChart | null>(null)
  const [chartReady, setChartReady] = useState(false)

  const [uniqueChartId] = useState(chartId ?? nanoid())
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: uniqueChartId, ...(state ?? emptyObject) })
  )

  const {
    cycleFilters,
    xAxisProperty,
    yAxisProperties,
    propertyFilters,
    normalizeByProperties,
    groupByProperty,
    aggregateByProperty,
    metricType,
    rawData
  } = chartState

  const [, chartOptions] = useAtom(apexChartOptionsAtom(uniqueChartId))
  const { options, type } = chartOptions()

  const setChartSelectedAnnotations = useSetAtom(chartSelectedAnnotationsAtom)

  // Reset selected annotations when chart state changes
  useEffect(() => {
    setChartSelectedAnnotations([])
  }, [
    cycleFilters,
    xAxisProperty,
    yAxisProperties,
    propertyFilters,
    normalizeByProperties,
    groupByProperty,
    aggregateByProperty,
    metricType,
    rawData,
    setChartSelectedAnnotations
  ])

  // gql
  const query =
    metricType === InsightsChartTypes.CYCLE_METRICS
      ? GET_CYCLE_SUMMARIES
      : GET_CYCLER_OBSERVATIONS

  const variables: Record<string, any> = {
    dataset_ids: datasetIds,
    organization_id: organization_id!,
    workspace_ids: [workspace_id!],
    additional_filter_keys: Object.values(propertyFilters).map(
      _propertyFilter => _propertyFilter.property?.key
    ),
    dataset_filters: Object.values(propertyFilters)
      .map(propertyFilter => ({
        key: propertyFilter.property?.key,
        filter_type: propertyFilter.filter_type,
        value: propertyFilter.values
      }))
      .filter(v => !isEmpty(v.key) && !isNil(v.value)), // Remove empty values as it causes a backend error
    y_properties: compact(yAxisProperties.map(property => property.key)), // Remove empty values as it causes a backend error
    x_property: xAxisProperty.key,
    group_by_property: groupByProperty?.key,
    aggregate_by: aggregateByProperty.key,
    normalize_by_properties: Object.entries(normalizeByProperties).map(
      ([axisPropertyKey, normalizeByProperty]) => ({
        axis_property: axisPropertyKey,
        normalize_by_property: {
          normalize_type: normalizeByProperty.type,
          value: normalizeByProperty.key
        }
      })
    )
  }

  if (metricType === InsightsChartTypes.IN_CYCLE_METRICS) {
    variables.cycle_filters =
      Object.keys(cycleFilters)?.map(_cycleFilterId => {
        const cycleFilter = cycleFilters[_cycleFilterId]

        return {
          key: cycleFilter.property?.key,
          filter_type: cycleFilter.filter_type,
          value: cycleFilter.values
        }
      }) || []

    // this is always hardcoded
    variables.additional_properties = ['mode']
  }

  if (metricType === InsightsChartTypes.CELL_METRICS) {
    variables.metric_target_limits = chartState.metricTargetLimits
    // Prevents request from failing due to missing cycle_filters and additional_properties
    variables.cycle_filters = []
    // this is always hardcoded
    variables.additional_properties = []
  }

  // Fetch chart raw data
  const [loadedOnce, setLoadedOnce] = useState(false)
  const { data, loading, refetch } = useQuery(query, {
    variables: variables as any,
    onError: error => {
      console.error(error)
    },
    skip:
      !organization_id || !workspace_id || (isEmpty(datasetIds) && !loadedOnce)
  })

  // Workaround for Apollo Client update, where onCompleted handler is not called when a refetch is completed
  useEffect(() => {
    if (!data) return
    let dataObj: Nullable<ObservationResults | SummaryResults> = null
    let rawData:
      | ObservationResults['datasets']
      | SummaryResults['summary_groups'] = []
    let xAxisProperty = chartState.xAxisProperty
    let yAxisProperties = chartState.yAxisProperties

    switch (metricType) {
      case InsightsChartTypes.CYCLE_METRICS:
      case InsightsChartTypes.CELL_METRICS:
        if ('get_cycle_summaries' in data) {
          dataObj = data.get_cycle_summaries as SummaryResults
          rawData = dataObj?.summary_groups ?? []
        }
        break
      case InsightsChartTypes.IN_CYCLE_METRICS:
        if ('get_cycler_observations' in data) {
          dataObj = data?.get_cycler_observations as ObservationResults
          rawData = dataObj?.datasets ?? []
        }
        break
      default:
        rawData = []
    }

    if (dataObj != null) {
      xAxisProperty = assign({}, xAxisProperty, dataObj.x_property)
      yAxisProperties = dataObj.y_properties.map((yProperty, index) =>
        assign({}, yAxisProperties[index], yProperty)
      )
    }

    setChartState(prev => ({
      ...prev,
      rawData,
      metricType,
      xAxisProperty,
      yAxisProperties
    }))
    setLoadedOnce(true)
  }, [data])

  // Fetch metric target limits - ONLY for cell metrics
  useQuery(LOAD_METRIC_TARGET_LIMITS, {
    variables: {
      organization_id: organization_id || '',
      workspace_id: workspace_id || ''
    },
    skip:
      metricType !== InsightsChartTypes.CELL_METRICS ||
      !organization_id ||
      !workspace_id,
    onCompleted: newMetricTargetLimits => {
      setChartState(prev => ({
        ...prev,
        metricTargetLimits:
          newMetricTargetLimits.load_metric_target_limits.metric_target_limits
      }))
    }
  })

  // // Update chartState when prop updates
  useEffect(() => {
    if (state == null) return
    setChartState((prev: InsightsChartState) => {
      const next = {
        ...prev,
        ...state,
        xAxisProperty: {
          label: state?.xAxisProperty?.label || prev?.xAxisProperty?.label,
          key: state?.xAxisProperty?.key || prev?.xAxisProperty?.key,
          labelOverride: state?.xAxisProperty?.labelOverride,
          units: state?.xAxisProperty?.units || prev?.xAxisProperty?.units
        },
        yAxisProperties: (state?.yAxisProperties || []).map(
          (yProperty, index) => {
            return {
              label: yProperty?.label || prev?.yAxisProperties?.[index]?.label,
              key: yProperty?.key || prev?.yAxisProperties?.[index]?.key,
              labelOverride: yProperty?.labelOverride,
              units: yProperty?.units || prev?.yAxisProperties?.[index]?.units
            }
          }
        )
      }
      return isEqual(prev, next) ? prev : next
    })
  }, [setChartState, state])

  // Update chartState when datasets update
  useEffect(() => {
    setChartState((prev: InsightsChartState) => {
      const next = {
        ...prev,
        datasets: datasets || [],
        datasetColumns: datasetColumns || [],
        datasetIds
      }

      return isEqual(prev, next) ? prev : next
    })
  }, [datasets, datasetColumns, datasetIds, setChartState])

  const callbackRef = useCallback((chart: ReactApexChart) => {
    if (chart == null) return
    chartRef.current = chart
    setChartReady(true)
  }, [])

  const isAnythingLoading = !loadedOnce || externalLoading || loading

  const hasMultipleCycles = _getDatasetHasMultipleCycles(rawData)
  const singleCycleNumber = rawData[0]?.cycles?.[0]?.cycle_number

  const annotationsDisabled =
    groupByProperty != null || Object.keys(normalizeByProperties).length > 0

  // Check if all series are empty.
  const seriesEmpty =
    !options.series ||
    options.series.reduce(
      (isEmpty, series) => isEmpty && series.data.length === 0,
      true
    )

  return (
    <div className='w-full'>
      <div
        className={cn(`flex items-center`, { '-my-5': !title && !reportMode })}
      >
        {title && (
          <Title level={5} className='!mb-0'>
            {title}
          </Title>
        )}
        <div className='flex-grow' />

        {!isAnythingLoading && showFilterControls && (
          <InsightsChartFilters
            chartId={uniqueChartId}
            chartRef={chartRef}
            filtersDisabled={!chartReady}
            annotationsDisabled={annotationsDisabled}
            refetchGraph={refetch}
          />
        )}
      </div>

      {subTitle && (
        <div className='text-xxs text-gray-500 font-medium mb-4 mt-2'>
          {subTitle}
        </div>
      )}

      {isAnythingLoading ? (
        <ChartLegendSkeleton />
      ) : (
        <ChartLegend
          chartId={uniqueChartId}
          seriesEmpty={
            seriesEmpty && metricType === InsightsChartTypes.CELL_METRICS
          }
        />
      )}

      {!reportMode &&
        !hasMultipleCycles &&
        !isAnythingLoading &&
        metricType === InsightsChartTypes.IN_CYCLE_METRICS && (
          <div className='text-xs text-gray-500 font-medium mt-4'>
            Results for Cycle Number
            <span className='ml-1 font-bold'>
              {singleCycleNumber
                ? numbro(singleCycleNumber).format({ thousandSeparated: true })
                : 'N/A'}
            </span>
          </div>
        )}

      {/**
       * When the chart is loading, show a skeleton, because when we don't, it is possible data
       * isn't finished processing or is still in an odd shape, and this can lead to chart crashes
       */}
      {isAnythingLoading ? (
        <ChartSkeleton />
      ) : (
        <ReactApexChart
          key={metricType}
          // @ts-expect-error
          options={options}
          type={type === 'rangeArea' ? 'rangeArea' : 'line'}
          series={options.series}
          height='440px'
          width='100%'
          ref={callbackRef}
        />
      )}
    </div>
  )
}

function ChartLegendSkeleton() {
  return (
    <div className='my-2 space-y-2'>
      <Skeleton.Node
        active
        className='!w-full [&_.ant-skeleton-image]:!h-8 [&_.ant-skeleton-image]:!w-full mt-2'
      />
    </div>
  )
}

function ChartSkeleton() {
  return (
    <div className='grid grid-cols-[1rem_1fr] gap-4'>
      <div className='h-[440px] flex gap-x-4 items-center'>
        <Skeleton.Node
          active
          className='!h-full [&.ant-skeleton-element]:!h-3/4 self-center [&_.ant-skeleton-image]:!w-4'
        />
      </div>
      <div>
        <Skeleton.Node
          active
          className='!h-full self-center [&.ant-skeleton-element]:!w-full [&_.ant-skeleton-image]:!w-full'
        />
      </div>
      <div></div>
      <div className='w-full flex items-center justify-center'>
        <Skeleton.Node
          active
          className='!w-3/4 [&_.ant-skeleton-image]:!h-4 [&_.ant-skeleton-image]:!w-full'
        />
      </div>
    </div>
  )
}

export default InsightsChart
