import {
  Filter_Option,
  Filter_Type,
  Property_Type
} from '../gql_generated/graphql'

export type SelectorOption = {
  key: any
  label: string
  group?: string
  type?: Nullable<string>
  units?: Nullable<string>
  metric_source?: Nullable<string>
}

export type PropertyFilter = {
  filter_id: string
  filter_type?: Filter_Type
  property: Nullable<Filter_Option>
}

export type PropertyFilterWithValues = {
  filter_type: Filter_Type
  property: Nullable<Filter_Option>
  values: Filter_Option[]
}

export type CycleFilterWithValues = {
  filter_type: Filter_Type
  property: Nullable<Filter_Option>
  values: Filter_Option[] | string | number
}

export const PLOTTABLE_PROPERTY_TYPES = [
  Property_Type.Float,
  Property_Type.Long
]

export type DataPointSelectionOpts = {
  dataPointIndex: number
  selectedDataPoints: number[][]
  seriesIndex: number
}

export type AnnotationRequestDataPoint = {
  datasetKey: string
  xProperty: SelectorOption
  xPropertyValue: number
  yProperty: SelectorOption
  yPropertyValue: number
}

export type AnnotationMap = {
  [datasetKey: string]: {
    [yProperty: string]: Array<{
      xPropertyValue: number
      yPropertyValue: number
    }>
  }
}

export const ANNOTATION_TYPES: SelectorOption[] = [
  {
    key: 'anomaly',
    label: 'Anomaly'
  },
  {
    key: 'power_outage',
    label: 'Power Outage'
  },
  {
    key: 'measurement_error',
    label: 'Measurement Error'
  },
  {
    key: 'internal_short_circuit',
    label: 'Internal Short Circuit'
  }
]
