import { Navigate, Outlet, RouterProvider } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'

import { WorkspaceRoles } from '@/constants'
import { UploadDatasets } from '@/datasets/upload/UploadDatasets'
import CellSpecifications from '@/insights/cell-specifications/CellSpecifications'
import { Insight_Dashboard } from '@/insights/home/Insight_Dashboard'
import { JupyterLite } from '@/insights/jupyter/JupyterLite'
import {
  AddVisualizations,
  ReportBuilder,
  ReportPreview,
  ReportView,
  SelectDatasets
} from '@/insights/reports/views/ReportBuilder'
import { ReportList } from '@/insights/reports/views/ReportList'
import { TemplateLibrary } from '@/insights/templateLibrary/TemplateLibrary'
import { AccountSettings } from '@/settings/views/AccountSettings'

import { Error_Fallback } from './Error_Fallback'
import { Navigation_Root } from './Navigation_Root'
import { RoleGuard } from './guards/RoleGuard'

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Navigation_Root />,
      errorElement: <Error_Fallback />,
      children: [
        {
          path: ':organization_key',
          children: [
            {
              path: ':workspace_key',
              element: <Outlet />,
              children: [
                { index: true, element: <Navigate to='home' /> },
                { path: 'home', element: <Insight_Dashboard /> },
                {
                  path: 'reports',
                  element: <Outlet />,
                  children: [
                    { index: true, element: <ReportList /> },
                    // Setup a redirect to template-library, since this path has been exposed to clients and changed
                    {
                      path: 'templates',
                      element: <Navigate to='../template-library/reports' />
                    },
                    { path: 'item/:reportId', element: <ReportView /> },
                    {
                      path: ':reportId',
                      element: <ReportBuilder />,
                      children: [
                        { index: true, element: <Navigate to='data' /> },
                        { path: 'data', element: <SelectDatasets /> },
                        {
                          path: 'visualizations',
                          element: <AddVisualizations />
                        },
                        { path: 'preview', element: <ReportPreview /> }
                      ]
                    }
                  ]
                },
                {
                  path: 'template-library',
                  element: <Outlet />,
                  children: [
                    { index: true, element: <TemplateLibrary /> },
                    { path: ':tabId', element: <TemplateLibrary /> }
                  ]
                },
                {
                  path: 'charts', // Setup a redirect to template-library, since this path has been exposed to clients and changed
                  element: <Outlet />,
                  children: [
                    {
                      index: true,
                      element: <Navigate to='../template-library/charts' />
                    }
                  ]
                },
                {
                  path: 'cell-specifications',
                  element: <CellSpecifications />
                },
                { path: 'notebooks', element: <JupyterLite /> },
                { path: 'upload-datasets', element: <UploadDatasets /> },
                { path: 'alerts', element: <>Coming Soon</> },
                {
                  path: 'account-settings',
                  element: (
                    <RoleGuard
                      permittedRoles={[WorkspaceRoles.Admin]}
                      redirectTo='../home'
                    >
                      <AccountSettings />
                    </RoleGuard>
                  )
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
      v7_partialHydration: true,
      v7_normalizeFormMethod: true,
      v7_fetcherPersist: true
    }
  }
)

export const Navigation = () => {
  return <RouterProvider router={router} />
}
