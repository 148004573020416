import { gql } from '@/gql_generated'

export const SAVE_SHARED_NOTEBOOK = gql(`
  mutation save_shared_notebook($id: String!, $file_name: String!, $organization_id: String!, $workspace_ids: [String!]!, $ipynb_json: String!, $description: String, $save_count: Int) {
    save_shared_notebook(
      id: $id, 
      file_name: $file_name, 
      organization_id: $organization_id, 
      workspace_ids: $workspace_ids, 
      ipynb_json: $ipynb_json,
      description: $description,
      save_count: $save_count
    ) {
      id
      file_name
      description
      save_count
      created_at
      updated_at
      ipynb_json
    }
  }
`)

export const GET_SHARED_NOTEBOOKS = gql(`
  query get_shared_notebooks($organization_id: String!, $workspace_ids: [String!]!, $page: Float!, $page_size: Float!) {
    get_shared_notebooks(organization_id: $organization_id, workspace_ids: $workspace_ids, page: $page, page_size: $page_size) {
      data {
        id
        file_name
        description
        save_count
        created_at
        updated_at
        ipynb_json
      }
      total
      page
      page_size     
    }
  }
`)

export const LOAD_SHARED_NOTEBOOK = gql(`
  query load_shared_notebook($id: String!, $workspace_ids: [String!]!) {
    load_shared_notebook(id: $id, workspace_ids: $workspace_ids) {
      id
      file_name
      description
      save_count
      created_at
      updated_at
      ipynb_json
    }
  }
`)

export const LOAD_SHARED_NOTEBOOK_BY_FILE_NAME = gql(`
  query load_shared_notebook_by_file_name($file_name: String!, $workspace_ids:  [String!]!) {
    load_shared_notebook_by_file_name(file_name: $file_name, workspace_ids: $workspace_ids) {
      id
      file_name
      description
      save_count
      created_at
      updated_at
      ipynb_json
    }
  }
`)

export const DELETE_SHARED_NOTEBOOK = gql(`
  mutation delete_shared_notebook($id: String!, $workspace_ids: [String!]!) {
    delete_shared_notebook(id: $id, workspace_ids: $workspace_ids) {
      id
      file_name
      description
      save_count
      created_at
      updated_at
      ipynb_json
    }
  }
`)
