import { useQuery } from '@apollo/client'
import { find } from 'lodash-es'
import { useParams } from 'react-router'

import { WorkspaceRoles } from '@/constants'

import { GET_ORGANIZATIONS } from '../../user/queries/get_organizations'

/**
 * Get the ids of the workspace and organization corresponding
 * to the workspace and organization keys in the URL.
 */
export const use_workspace_and_org_ids = () => {
  // Ignoring rules of hooks as the linter doesn't recognize use_ as
  // a valid hook prefix.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { organization_key, workspace_key } = useParams()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: organizations_data } = useQuery(GET_ORGANIZATIONS, {
    skip: !organization_key || !workspace_key
  })

  const organization_id = find(
    organizations_data?.get_current_user_organizations || [],
    { key: organization_key }
  )?.id

  const workspaces = organizations_data?.get_current_user_organizations
    .map(organization => organization.workspaces)
    .flat()

  const workspace_id = find(workspaces || [], { key: workspace_key })?.id
  const workspace_role =
    find(workspaces || [], { key: workspace_key })?.role ||
    WorkspaceRoles.Member

  return { workspace_id, organization_id, workspace_role }
}
