import { Contents } from '@jupyterlab/services'
import dayjs from 'dayjs'
import { Differ, Viewer } from 'json-diff-kit'
import 'json-diff-kit/dist/viewer.css'
import { createContext } from 'react'

import { DateFormats } from '@/constants'
import { Iris_Shared_Notebook } from '@/gql_generated/graphql'
import { cn } from '@/utils'

type PotentialMatch = {
  existingNotebook?: Iris_Shared_Notebook
  newNotebook: Contents.IModel
}
export type NotebookMatches = {
  [fileName: string]: PotentialMatch
}

type ModalParentContextType = {
  matches: NotebookMatches
}

const defaultContextValue: ModalParentContextType = {
  matches: {}
}
const ModalParentContext =
  createContext<ModalParentContextType>(defaultContextValue)

export const ConfirmOverwriteSharedNotebooksConfig = {
  title: 'Exisiting Notebook(s) Found',
  className: cn('!w-3/5 h-5/6 p-0'), //, '[&>div]:h-full [&_.ant-modal-content]:h-full','[&_.ant-modal-body]:overflow-hidden [&_.ant-modal-body]:h-full', '[&_.ant-modal-confirm-body-wrapper]:h-full [&_.ant-modal-confirm-body]:h-full [&_.ant-modal-confirm-paragraph]:h-full'),
  content: (
    <ModalParentContext.Consumer>
      {({ matches }) => {
        const differ = new Differ({
          arrayDiffMethod: 'lcs'
        })
        if (matches == null) return null

        const { newNotebook, existingNotebook } = Object.values(matches)[0]

        if (existingNotebook == null) return null

        const existingNotebookJson = JSON.parse(existingNotebook.ipynb_json)
        const diff = differ.diff(existingNotebookJson, newNotebook)

        const linesAdded = diff[1].filter(d => d.type === 'add').length
        const linesRemoved = diff[1].filter(d => d.type === 'remove').length
        const linesModified = diff[1].filter(d => d.type === 'modify').length

        // Scroll to the first diff
        setTimeout(() => {
          document
            .querySelector('.json-diff-viewer td:not(.line-equal)')
            ?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
        }, 0)

        return (
          <div>
            <div>
              <p>
                Do you want to overwrite the existing notebook with the new one?
              </p>
              <strong className='text-amber-500'>
                Warning: This notebook will be saved and shared with all users
                who have access to this workspace.
              </strong>
            </div>
            <div className='bg-gray-100 p-4 my-4 rounded-md'>
              <div className='grid grid-cols-4 gap-4 font-bold'>
                <div>File Name</div>
                <div>Last Modified on Server</div>
                <div>Date Created</div>
                <div>Size</div>
              </div>
              <div key={newNotebook.name} className='grid grid-cols-4 gap-4'>
                <div>{existingNotebook.file_name}</div>
                <div>
                  {dayjs
                    .utc(existingNotebook.updated_at)
                    .local()
                    .format(DateFormats.DATE_TIME_WITH_TZ)}
                </div>
                <div>
                  {dayjs
                    .utc(existingNotebook.created_at)
                    .local()
                    .format(DateFormats.DATE_TIME_WITH_TZ)}
                </div>
                <div>{existingNotebookJson.size} bytes</div>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className='text-md font-semibold mb-2 flex flex-row justify-between'>
                <div>Diff:</div>
                <div className='ml-2 text-sm flex flex-row gap-x-2 border-gray-300 border rounded-md px-2 py-0.5'>
                  Changes:
                  <span className='text-green-500' title='Added'>
                    +{linesAdded}
                  </span>
                  <span className='text-red-500' title='Removed'>
                    -{linesRemoved}
                  </span>
                  <span className='text-amber-500' title='Modified'>
                    ~{linesModified}
                  </span>
                </div>
              </div>
              <div className='h-[40vh] overflow-y-auto'>
                <Viewer
                  diff={diff}
                  lineNumbers
                  highlightInlineDiff={true}
                  inlineDiffOptions={{
                    mode: 'word',
                    wordSeparator: ' '
                  }}
                />
              </div>
            </div>
          </div>
        )
      }}
    </ModalParentContext.Consumer>
  )
}

export const ConfirmOverwriteSharedNotebooksProvider =
  ModalParentContext.Provider
