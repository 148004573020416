import { XAxis } from '@carbon/icons-react'
import { AutoTextSize } from 'auto-text-size'
import { useAtomValue } from 'jotai'
import { cloneDeep, startCase } from 'lodash-es'
import { FocusEvent, useMemo } from 'react'

import { DebouncedInput, OptGroup, Select } from '@/components'
import { RangeValue } from '@/constants'
import {
  Filter_Option,
  Normalize_Type,
  Property_Option
} from '@/gql_generated/graphql'
import { makeAxisTitle } from '@/insights/charts/transform'
import { format_property_label } from '@/insights/charts/utils'
import Numeric_Filter from '@/insights/controls/filters/Numeric_Filter'
import { NormalizeByOption } from '@/insights/jotai/charts.atoms'
import {
  VisualizationType,
  visualizationAtomFamily
} from '@/insights/reports/store/report.molecule'
import { mapObjectKeyToValue } from '@/utils'

import { MenuHeader, MenuItemControl } from '../../../controls'
import { useVisualizationItem } from '../../VisualizationContext'

type PanelXAxisProps = {
  propertyOptions: (Property_Option | Filter_Option)[]
  normalizeOptions?: NormalizeByOption[]
  loading?: boolean
}

/**
 * PanelXAxis component for configuring the X-Axis of a chart visualization.
 *
 * @param {PanelXAxisProps} props - The properties for the component.
 */
export function PanelXAxis(props: PanelXAxisProps) {
  const { propertyOptions, normalizeOptions = [], loading = true } = props
  const { visualizationId, updateVisualizationConfig } = useVisualizationItem()
  const visualization = useAtomValue(
    visualizationAtomFamily({ id: visualizationId })
  )

  const { config, type } = visualization

  const selectablePropertyOptions = useMemo(() => {
    return propertyOptions.map(mapObjectKeyToValue)
  }, [propertyOptions])

  const groupedPropertyOptions = useMemo(() => {
    const options: OptGroup[] = []
    return selectablePropertyOptions.reduce((acc, option) => {
      const sourceName = option.metric_source ?? 'Other'
      const group = acc.find(g => g.title === sourceName)

      if (group) {
        group.options.push(option)
      } else {
        acc.push({
          label: (
            <AutoTextSize
              maxFontSizePx={14}
              minFontSizePx={11}
              className='font-semibold text-gray-500 self-center'
            >
              {sourceName}
            </AutoTextSize>
          ),
          className: '!sticky -top-1 !bg-white !rounded-none',
          title: sourceName,
          options: [option]
        })
      }

      return acc
    }, options)
  }, [selectablePropertyOptions])

  const selectableNormalizeOptions = useMemo(
    () => normalizeOptions.map(mapObjectKeyToValue),
    [normalizeOptions]
  )

  if (type !== VisualizationType.Chart) return null
  const { xAxisProperty, xPropertyRange, normalizeByProperties } = config
  /**
   * Handles the change of the metric for the X-axis.
   *
   * @param {string} newMetric - The new property to be set for the X-axis.
   */
  const onMetricChange = (newMetric: string) => {
    if (selectablePropertyOptions == null) return
    const xProperty = selectablePropertyOptions.find(
      ({ key }) => key === newMetric
    )
    if (xProperty == null) return

    updateVisualizationConfig({
      xAxisProperty: {
        ...xProperty,
        labelOverride: undefined
      }
    })
  }

  /**
   * Handles the change of the normalization metric for the X-axis.
   *
   * @param {string} normalizeBy - The new property to be set for the X-axis normalization.
   */
  const onNormalizeByChange = (normalizeBy: Nullable<string>) => {
    const { key } = xAxisProperty
    const nextNormalizeByProperties = cloneDeep(normalizeByProperties)
    const selectedOption = normalizeOptions.find(
      ({ key }) => key === normalizeBy
    )

    if (normalizeBy != null) {
      nextNormalizeByProperties[key] = {
        ...selectedOption,
        key: normalizeBy,
        type: Normalize_Type.DatasetProperty,
        label: selectedOption?.label ?? startCase(normalizeBy)
      }
    } else {
      delete nextNormalizeByProperties[key]
    }

    updateVisualizationConfig({
      normalizeByProperties: nextNormalizeByProperties,
      xAxisProperty: {
        ...xAxisProperty,
        labelOverride: undefined
      }
    })
  }

  /**
   * Handles the change of the label override for the X-axis.
   * Debounces the change to prevent too many updates.
   */
  const onAxisLabelChange = (newValue = '') => {
    let nextXAxisProperty = cloneDeep(xAxisProperty)
    if (newValue.trim().length > 0) {
      nextXAxisProperty.labelOverride = newValue
    } else {
      delete nextXAxisProperty.labelOverride
    }

    updateVisualizationConfig({
      xAxisProperty: nextXAxisProperty
    })
  }

  /**
   * Creates a handler for changing the range value of the X-axis.
   *
   * @param {RangeValue} pos - The position (Min or Max) of the range value.
   */
  const makeOnRangeValueChange =
    (pos: RangeValue) => (event: FocusEvent<HTMLInputElement, Element>) => {
      const value = parseFloat(event.target.value)
      updateVisualizationConfig({
        xPropertyRange: [
          pos === RangeValue.Min ? value : xPropertyRange[0],
          pos === RangeValue.Max ? value : xPropertyRange[1]
        ]
      })
    }

  const xAxisNormalizaByProperty = normalizeByProperties?.[xAxisProperty.key]
  return (
    <div>
      <MenuHeader
        title={
          <div className='flex flex-row gap-x-2 items-center'>
            <XAxis />
            X-Axis
          </div>
        }
      />
      <div className='p-2'>
        <div className='flex flex-col gap-2 items-stretch'>
          <MenuItemControl
            label='X-Axis'
            inputId='x-axis-type'
            emphasize
            loading={loading}
          >
            <Select
              allowClear={false}
              aria-label='X-Axis Type'
              id='x-axis-type'
              options={groupedPropertyOptions}
              placeholder='Add Metric'
              showSearch
              className='flex-1'
              value={{
                ...xAxisProperty,
                label: format_property_label(
                  xAxisProperty.label,
                  xAxisProperty.units
                )
              }}
              onChange={onMetricChange}
              virtual={false}
            />
          </MenuItemControl>
          <MenuItemControl
            label='Normalize by'
            inputId='x-axis-normalize-by'
            loading={loading}
          >
            <Select
              allowClear
              aria-label='X-Axis Normalize By'
              className='flex-1'
              id='x-axis-normalize-by'
              onChange={onNormalizeByChange}
              options={selectableNormalizeOptions}
              placeholder='Add Metric'
              showSearch
              value={
                xAxisNormalizaByProperty
                  ? format_property_label(
                      xAxisNormalizaByProperty.label,
                      xAxisNormalizaByProperty.units
                    )
                  : undefined
              }
            />
          </MenuItemControl>
          <MenuItemControl label='Label Override' inputId='x-axis-label'>
            <DebouncedInput
              allowClear
              className='text-xs h-8'
              id='x-axis-label'
              onChange={onAxisLabelChange}
              placeholder={makeAxisTitle(xAxisProperty, normalizeByProperties)}
              value={xAxisProperty.labelOverride ?? ''}
            />
          </MenuItemControl>
          <MenuItemControl label='Limits (Min - Max)' inputId='x-axis-limits'>
            <Numeric_Filter
              placeholder='Min'
              onBlur={makeOnRangeValueChange(RangeValue.Min)}
              value={xPropertyRange[0] ?? undefined}
              sx={{ '.MuiInputBase-input': { fontSize: '0.75rem' } }}
            />
            <Numeric_Filter
              placeholder='Max'
              onBlur={makeOnRangeValueChange(RangeValue.Max)}
              value={xPropertyRange[1] ?? undefined}
              sx={{ '.MuiInputBase-input': { fontSize: '0.75rem' } }}
            />
          </MenuItemControl>
        </div>
      </div>
    </div>
  )
}
