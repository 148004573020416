import { useQuery } from '@apollo/client'
import { Add, TrashCan } from '@carbon/icons-react'
import { Box, Button, Grid2, TextField } from '@mui/material'
import { useAtom } from 'jotai'
import { cloneDeep } from 'lodash-es'
import { FocusEvent, useMemo } from 'react'

import {
  Filter_Config,
  Filter_Option,
  Normalize_Type,
  Property_Option
} from '@/gql_generated/graphql'
import { makeAxisTitle } from '@/insights/charts/transform'
import {
  NormalizeByOption,
  chartStatesFamily
} from '@/insights/jotai/charts.atoms'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { use_app_selector } from '@/state/redux/hooks'

import { select_property_filter_keys } from '../charts/cycle_observations/cycler_observations_chart_slice'
import { GET_CYCLE_OBSERVATION_FILTER_CONFIGS } from '../charts/cycle_observations/queries/get_cycler_observation_filter_configs'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../charts/cycle_summaries/queries/get_cycle_summary_properties'
import { Any_Time_Byterat_Property } from '../charts/models/byterat_properties.model'
import { InsightsChartTypes } from '../charts/types'
import { format_property_label } from '../charts/utils'
import { select_staged_dataset_table_rows } from '../home/insights_slice'
import { PLOTTABLE_PROPERTY_TYPES } from '../types'
import { Autocomplete_Filter } from './filters/Autocomplete_Filter'
import Numeric_Filter from './filters/Numeric_Filter'
import { Menu_Header, Menu_Item_Control, use_menu_nav } from './menus'

const MAX_Y_AXIS_METRICS = 2

export function InsightsPanelYAxis({ chartId }: { chartId: string }) {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  // Jotai chart state
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: chartId })
  )
  const {
    metricType,
    yAxisProperties,
    yPropertyRanges,
    normalizeByProperties
  } = chartState

  const additional_filter_keys = use_app_selector(select_property_filter_keys)
  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )

  function handleYRangeChange(
    index: number,
    newRange: [number | null, number | null]
  ) {
    const _newYRanges = [...(yPropertyRanges || [])]
    _newYRanges[index] = newRange

    setChartState({
      ...chartState,
      yPropertyRanges: _newYRanges
    })
  }

  const {
    data: cycleSummaryPropertiesData,
    loading: cycleSummaryPropertiesLoading
  } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id
  })

  const {
    data: observationFilterConfigsData,
    loading: observationFilterConfigsLoading
  } = useQuery(GET_CYCLE_OBSERVATION_FILTER_CONFIGS, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string],
      dataset_ids: (staged_dataset_table_rows || []).map(row => row.id),
      dataset_filters: [],
      additional_filter_keys
    },
    skip:
      !organization_id ||
      !workspace_id ||
      metricType !== InsightsChartTypes.IN_CYCLE_METRICS
  })

  // Compute the observation property options
  const observationPropertyOptions = useMemo(() => {
    let result: (Filter_Option | Property_Option)[] = []

    switch (metricType) {
      case InsightsChartTypes.IN_CYCLE_METRICS:
        result =
          observationFilterConfigsData?.get_observation_filter_configs?.find(
            (property: Filter_Config) =>
              property.filter_property === 'plottable_observation_property'
          )?.options || []
        break
      case InsightsChartTypes.CYCLE_METRICS:
        result =
          cycleSummaryPropertiesData?.get_cycle_summary_properties?.cycle_summary_properties?.filter(
            (property: Property_Option) =>
              property.type && PLOTTABLE_PROPERTY_TYPES.includes(property.type)
          ) || []
        break
      case InsightsChartTypes.CELL_METRICS:
        result =
          cycleSummaryPropertiesData?.get_cycle_summary_properties?.dataset_properties?.filter(
            (property: Property_Option) =>
              property.type && PLOTTABLE_PROPERTY_TYPES.includes(property.type)
          ) || []
        break
      default:
        result = []
    }

    if (result.length !== 0) {
      return result
        .filter(
          // Remove any time-based properties
          (option: any) => !Any_Time_Byterat_Property.includes(option?.key)
        )
        .map(v => ({
          ...v,
          label: format_property_label(v.label, v.units)
        }))
        .sort((a, b) => {
          if (a.metric_source === b.metric_source) {
            return a.label.localeCompare(b.label)
          }
          return a.metric_source?.localeCompare(b.metric_source ?? '') ?? 0
        })
    }

    return []
  }, [cycleSummaryPropertiesData, observationFilterConfigsData, metricType])

  const normalizeByOptions = useMemo(() => {
    const datasetProperties =
      cycleSummaryPropertiesData?.get_cycle_summary_properties
        ?.dataset_properties || []

    return datasetProperties
      .filter(({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type))
      .map(({ key, label, units }) => ({
        key,
        units,
        type: Normalize_Type.DatasetProperty,
        group: 'Dataset Metrics',
        label: format_property_label(label, units)
      }))
  }, [cycleSummaryPropertiesData])

  const isAnythingLoading =
    observationFilterConfigsLoading || cycleSummaryPropertiesLoading

  return (
    <>
      <Menu_Header title='Y-Axis' on_go_back={return_to_root} />
      <Box width={500} paddingBottom={2} paddingInline={1}>
        {yAxisProperties.map((filterOption, index) => {
          return (
            <>
              <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
                <Menu_Item_Control
                  input_id={`y-axis-${index}-type`}
                  label={`Y-Axis ${index + 1}`}
                  emphasize
                >
                  <Autocomplete_Filter
                    id={`y-axis-${index}-type`}
                    aria-labelledby={`y-axis-${index}-type`}
                    disableClearable
                    grid_item_size={7}
                    loading={isAnythingLoading}
                    multiple={false}
                    onChange={(_e: any, newYProperty: Filter_Option) => {
                      const _newYProperties = [...(yAxisProperties || [])]
                      _newYProperties[index] = {
                        ...newYProperty,
                        labelOverride: undefined
                      }

                      const _newYRanges = [...(yPropertyRanges || [])]
                      _newYRanges[index] = [null, null]

                      setChartState({
                        ...chartState,
                        yAxisProperties: _newYProperties,
                        yPropertyRanges: _newYRanges
                      })
                    }}
                    // @ts-expect-error
                    options={observationPropertyOptions}
                    placeholder='Add Metric'
                    value={{
                      ...filterOption,
                      label: format_property_label(
                        filterOption.label,
                        filterOption.units
                      )
                    }}
                    getOptionDisabled={
                      yAxisProperties.length > 1
                        ? (option: Filter_Option) =>
                            yAxisProperties.some(
                              filter => filter.key === option.key
                            )
                        : undefined
                    }
                    groupBy={option => option.metric_source ?? ''}
                  />
                </Menu_Item_Control>

                <Menu_Item_Control
                  input_id={`y-axis-${index}-normalize-by`}
                  label='Normalize by'
                >
                  <Autocomplete_Filter
                    id={`y-axis-${index}-normalize-by`}
                    aria-labelledby={`y-axis-${index}-normalize-by`}
                    disableClearable={false}
                    grid_item_size={7}
                    loading={isAnythingLoading}
                    multiple={false}
                    onChange={(
                      _e: any,
                      newNormalizeBy: Nullable<NormalizeByOption>
                    ) => {
                      const _newNormalizeByProperties = cloneDeep(
                        normalizeByProperties
                      )
                      if (newNormalizeBy != null) {
                        _newNormalizeByProperties[filterOption.key] =
                          newNormalizeBy
                      } else {
                        delete _newNormalizeByProperties[filterOption.key]
                      }

                      setChartState({
                        ...chartState,
                        yAxisProperties: yAxisProperties.map(filter =>
                          filter.key === filterOption.key
                            ? { ...filter, labelOverride: undefined }
                            : filter
                        ),
                        normalizeByProperties: _newNormalizeByProperties
                      })
                    }}
                    options={normalizeByOptions}
                    placeholder='Add Metric'
                    value={{
                      ...normalizeByProperties[filterOption.key],
                      label: format_property_label(
                        normalizeByProperties[filterOption.key]?.label,
                        normalizeByProperties[filterOption.key]?.units
                      )
                    }}
                  />
                </Menu_Item_Control>
                <Menu_Item_Control
                  label='Label Override'
                  input_id={`y-axis-label-${index}`}
                >
                  <Grid2 size={7}>
                    <TextField
                      className='text-xs w-full'
                      id={`y-axis-label-${index}`}
                      onFocus={event => {
                        if (event.target.value.trim().length === 0) {
                          event.target.value = makeAxisTitle(
                            filterOption,
                            normalizeByProperties
                          )
                        }
                        event.target.select()
                      }}
                      // On blur, if the value is the same as the default, clear it
                      // because likely the user didn't change it, as it's the default
                      onBlur={(
                        event: FocusEvent<HTMLInputElement, Element>
                      ) => {
                        if (
                          event.target.value.trim() ===
                          makeAxisTitle(filterOption, normalizeByProperties)
                        ) {
                          event.target.value = ''
                        }
                      }}
                      onChange={(
                        event: FocusEvent<HTMLInputElement, Element>
                      ) => {
                        const _newYProperties = [...(yAxisProperties || [])]
                        const newValue = event.target.value

                        if (newValue.trim().length > 0) {
                          _newYProperties[index] = {
                            ..._newYProperties[index],
                            labelOverride: newValue
                          }
                        } else {
                          delete _newYProperties[index].labelOverride
                        }

                        setChartState({
                          ...chartState,
                          yAxisProperties: _newYProperties
                        })
                      }}
                      value={filterOption.labelOverride ?? ''}
                      placeholder={makeAxisTitle(
                        filterOption,
                        normalizeByProperties
                      )}
                    />
                  </Grid2>
                </Menu_Item_Control>

                <Menu_Item_Control
                  input_id={`y-axis-${index}-limits`}
                  label='Limits (Min - Max)'
                >
                  <Grid2
                    flexDirection='row'
                    columnGap={1}
                    display='inline-flex'
                    size={7}
                  >
                    <Numeric_Filter
                      placeholder='Min'
                      onBlur={event => {
                        const _parsedValue = Number(event.target.value)

                        handleYRangeChange(index, [
                          _parsedValue === 0
                            ? event.target.value === '0'
                              ? 0
                              : null
                            : _parsedValue,
                          yPropertyRanges?.[index]?.[1]
                        ])
                      }}
                      value={yPropertyRanges?.[index]?.[0] as number}
                    />

                    <Numeric_Filter
                      placeholder='Max'
                      onBlur={event => {
                        const _parsedValue = Number(event.target.value)

                        handleYRangeChange(index, [
                          yPropertyRanges?.[index]?.[0],
                          _parsedValue === 0
                            ? event.target.value === '0'
                              ? 0
                              : null
                            : _parsedValue
                        ])
                      }}
                      value={yPropertyRanges?.[index]?.[1] as number}
                    />
                  </Grid2>
                </Menu_Item_Control>
              </Grid2>

              {yAxisProperties.length > 1 && (
                <Grid2
                  display='flex'
                  size='grow'
                  justifyContent='flex-end'
                  paddingInline={1}
                >
                  <Button
                    variant='text'
                    size='small'
                    startIcon={<TrashCan width={16} />}
                    onClick={() => {
                      const _newYProperties = yAxisProperties.filter(
                        (_filter, _index) => _index !== index
                      )
                      const _newYRanges = yPropertyRanges.filter(
                        (_range, _index) => _index !== index
                      )
                      const _newNormalizeByProperties = cloneDeep(
                        normalizeByProperties
                      )
                      delete _newNormalizeByProperties[filterOption.key]

                      setChartState({
                        ...chartState,
                        yAxisProperties: _newYProperties,
                        yPropertyRanges: _newYRanges,
                        normalizeByProperties: _newNormalizeByProperties
                      })
                    }}
                  >
                    Remove Y-Axis
                  </Button>
                </Grid2>
              )}
            </>
          )
        })}

        {yAxisProperties.length < MAX_Y_AXIS_METRICS && (
          <Button
            variant='text'
            size='small'
            startIcon={<Add width={16} />}
            onClick={() => {
              setChartState({
                ...chartState,
                yAxisProperties: [
                  ...yAxisProperties,
                  { key: '', label: '', units: '' }
                ]
              })
            }}
          >
            Add Y-Axis
          </Button>
        )}
      </Box>
    </>
  )
}
