import BigNumber from 'bignumber.js'
import { cloneDeep, sortBy } from 'lodash-es'

import {
  CHART_COLORS,
  DEFAULT_FILL_MARKER_SIZE,
  MARKER_SHAPE_SIZE,
  VALID_SCATTER_SHAPES
} from '../../charts'
import { InsightsChartTypes } from '../../charts/types'
import { format_property_label } from '../../charts/utils'
import { InsightsChartState } from '../charts.atoms'
import { isAggregateByNone } from './shared'
import { ApexChartSeries, ApexChartSeriesLegendItem } from './types'

export const _getCellMetricsSeries = ({
  datasets,
  datasetColumns,
  datasetIds,
  xAxisProperty,
  yAxisProperties,
  groupByProperty,
  normalizeByProperties
}: Pick<
  InsightsChartState,
  | 'datasets'
  | 'datasetColumns'
  | 'datasetIds'
  | 'xAxisProperty'
  | 'yAxisProperties'
  | 'groupByProperty'
  | 'normalizeByProperties'
>) => {
  const colors = cloneDeep(CHART_COLORS)
  let computedY1Min: number | undefined = undefined
  let computedY1Max: number | undefined = undefined

  const markerShapes: string[] = []
  const markerSizes: number[] = []
  const series: ApexChartSeries[] = []
  const seriesLegendItems: ApexChartSeriesLegendItem[] = []

  const _isAggregateByNone = isAggregateByNone(
    InsightsChartTypes.CELL_METRICS,
    groupByProperty,
    null
  )

  // handle empty states
  if (datasetIds.length === 0) {
    yAxisProperties.forEach(yProperty => {
      series.push({
        name: format_property_label(yProperty.label, yProperty.units),
        id: yProperty.key,
        data: []
      })
    })
  }

  let colorIndex = 0
  datasetIds.forEach(datasetId => {
    const dataset = datasets.find(({ id }) => id === datasetId)
    if (!dataset) return

    const annotations = dataset.annotations
    yAxisProperties.forEach((yProperty, yPropertyIndex) => {
      const group = datasetId
      const units = datasetColumns.find(
        property => property.key === yProperty.key
      )?.units
      const yPropertyLabel = format_property_label(yProperty.label, units)
      const name = `${group} ${yPropertyLabel}`

      let data: {
        x: number
        y: number
        annotations?: string[]
      }[] = []

      const xValue = dataset.properties?.find(
        (_property: any) => _property.key === xAxisProperty.key
      )?.value
      const yValue = dataset.properties?.find(
        (_property: any) => _property.key === yProperty.key
      )?.value

      if (
        xValue !== null &&
        yValue !== null &&
        !isNaN(xValue) &&
        !isNaN(yValue)
      ) {
        // Normalize x and y values if applicable
        const normalizeXProperty = normalizeByProperties?.[xAxisProperty.key]
        const normalizeYProperty = normalizeByProperties?.[yProperty.key]

        const normalizedXPropertyValue = normalizeXProperty
          ? dataset.properties?.find(
              (_property: any) => _property.key === normalizeXProperty.key
            )?.value
          : undefined
        const normalizedYPropertyValue = normalizeYProperty
          ? dataset.properties?.find(
              (_property: any) => _property.key === normalizeYProperty.key
            )?.value
          : undefined

        const normalizedXValue = normalizedXPropertyValue
          ? xValue / normalizedXPropertyValue
          : xValue
        const normalizedYValue = normalizedYPropertyValue
          ? yValue / normalizedYPropertyValue
          : yValue

        data.push({
          x: BigNumber(normalizedXValue).toNumber(),
          y: BigNumber(normalizedYValue).toNumber(),
          annotations
        })

        // update computedY1Range
        if (
          yPropertyIndex === 0 &&
          (computedY1Min === undefined || yValue < computedY1Min)
        ) {
          computedY1Min = yValue
        }
        if (
          yPropertyIndex === 0 &&
          (computedY1Max === undefined || yValue > computedY1Max)
        ) {
          computedY1Max = yValue
        }
      }

      // Save marker shape and size
      const _markerShape =
        VALID_SCATTER_SHAPES[yPropertyIndex % VALID_SCATTER_SHAPES.length]
      markerShapes.push(_markerShape)
      markerSizes.push(
        MARKER_SHAPE_SIZE[_markerShape] || DEFAULT_FILL_MARKER_SIZE
      )

      if (yPropertyIndex !== 0 && colorIndex !== 0) {
        // Use same color as previous series if this is the 2nd y-axis.
        // This is so that there is a consistent color for each dataset.
        const prevColor = colors[colorIndex - 1]
        colors.splice(colorIndex, 0, prevColor)
      }

      if (_isAggregateByNone) {
        // Grouped value can be read from the dataset properties
        const seriesGroupName =
          dataset.properties?.find(
            (_property: any) => _property.key === groupByProperty?.key
          )?.value || 'No Value'

        const existingSeriesGroup = seriesLegendItems.find(
          item => item.name === seriesGroupName
        )

        if (!existingSeriesGroup) {
          // Only add a new series group if it doesn't already exist
          const color =
            CHART_COLORS[seriesLegendItems.length % CHART_COLORS.length]
          seriesLegendItems.push({
            name: seriesGroupName,
            color,
            metadata: {
              group: seriesGroupName
            }
          })

          colors[colorIndex] = color
        } else {
          colors[colorIndex] = existingSeriesGroup.color
        }

        series.push({ name, id: group, data: sortBy(data, 'x') })
      } else {
        series.push({ name, id: group, data: sortBy(data, 'x') })
        if (yPropertyIndex === 0) {
          // Add a new series legend item for the dataset only for first y-axis
          seriesLegendItems.push({
            name: datasetId,
            color: colors[colorIndex],
            metadata: {
              group: datasetId
            }
          })
        }
      }

      // Increment color index
      colorIndex++
    })
  })

  return {
    colors,
    computedY1Min,
    computedY1Max,
    markerShapes,
    markerSizes,
    series,
    seriesLegendItems
  }
}
