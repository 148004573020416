import { ChevronDown } from '@carbon/icons-react'
import { Select as AntdSelect, SelectProps } from 'antd'
import { AutoTextSize } from 'auto-text-size'
import { FC } from 'react'

import { cn } from '../../utils'

export type OptGroup = {
  label: React.ReactNode
  className: string
  title: string
  options: any[]
}

export const Select: FC<SelectProps<any>> = props => {
  const { className = '', popupClassName, ...rest } = props
  return (
    <AntdSelect
      suffixIcon={<ChevronDown size='1.125rem' />}
      {...rest}
      optionRender={option => {
        return (
          <AutoTextSize
            className='flex flex-row items-center'
            maxFontSizePx={14}
            minFontSizePx={11}
          >
            {option.label}
          </AutoTextSize>
        )
      }}
      popupClassName={cn(
        '[&_.ant-select-item-group]:!z-[2]',
        '[&_.ant-select-item-option-grouped]:!z-0',
        popupClassName
      )}
      className={cn(
        'max-w-full w-full',
        `[&_.ant-select-selection-item]:text-xs`,
        `[&_.ant-select-selection-search-input]:text-xs`,
        `[&_.ant-select-selection-placeholder]:text-xs`,

        className
      )}
    />
  )
}
