export const JupyterBridgeId = 'jupyter-iframe-bridge'

export enum JupyterBridgeMessageType {
  Ack = 'ack',
  Error = 'error',
  Identify = 'identify',
  Initialized = 'initialized',
  NotebookOpened = 'notebook-opened',
  Operation = 'operation',
  Ready = 'ready',
  SharedExtensionBlocked = 'rename-prevented',
  Token = 'token',
  SaveSharedNotebook = 'save-shared-notebook',
  LoadSharedNotebook = 'load-shared-notebook',
  CopyToClipboard = 'copy-to-clipboard'
}

export enum JupyterBridgeOperationState {
  InProgress = 'in-progress',
  Complete = 'complete',
  Error = 'error',
  Token = 'token'
}

export type JupyterBridgeMessageEvent<T = unknown> = MessageEvent<{
  source: string
  type: JupyterBridgeMessageType | string
  payload?: string | T | T[]
}>
