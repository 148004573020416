import { useAtom } from 'jotai'
import { groupBy, isEqual, sortBy, uniqWith } from 'lodash-es'

import { ApexChartSeriesLegendItem } from '@/insights/jotai/types'

import { apexChartOptionsAtom } from '../../jotai/apex.atoms'
import { CHART_TYPE } from '../chart_options'
import { Line_Icon } from './Line_Icon'
import { Marker_Icon } from './Marker_Icon'

type ChartLegendItemProps = {
  color: string
  name: string
}
const ChartLegendItem = (props: ChartLegendItemProps) => {
  const { color, name } = props
  return (
    <div className='flex items-center gap-1'>
      <Marker_Icon marker='circle' color={color} />
      {name}
    </div>
  )
}

type ChartLegendProps = {
  chartId: string
  seriesEmpty?: boolean
}
export const ChartLegend = ({ chartId, seriesEmpty }: ChartLegendProps) => {
  const [, chartOptions] = useAtom(apexChartOptionsAtom(chartId))

  const chartType = chartOptions().type as CHART_TYPE
  const seriesLegendItems = uniqWith(
    chartOptions().options.seriesLegendItems,
    isEqual
  )
  const yAxes = chartOptions().options.yaxis
  let groupedSeriesLegendItems: Record<string, ApexChartSeriesLegendItem[]> = {}
  const showGroupedItems =
    seriesLegendItems.some(item => item.metadata.subGroup) &&
    Object.values(groupBy(seriesLegendItems, 'metadata.group')).some(
      groupItems => groupItems.length > 1
    )

  if (showGroupedItems) {
    groupedSeriesLegendItems = groupBy(seriesLegendItems, 'metadata.group')
    Object.keys(groupedSeriesLegendItems).forEach(group => {
      groupedSeriesLegendItems[group] = sortBy(
        groupedSeriesLegendItems[group],
        item => item.metadata.index
      )
    })
  }

  const isMultiAxis = yAxes.length > 1

  return (
    <div className='text-xs'>
      <div className='flex flex-wrap items-center gap-4 min-h-4'>
        {isMultiAxis &&
          yAxes.map((yAxis, index) => {
            return (
              <div
                key={`${yAxis?.title?.text}-${index}`}
                className='flex items-center gap-2'
              >
                {chartType === CHART_TYPE.LINE ? (
                  <Line_Icon dash_array={yAxis.dashArray || 0} />
                ) : (
                  <Marker_Icon marker={yAxis.marker} />
                )}

                {yAxis?.title?.text}
              </div>
            )
          })}
      </div>

      <div className='mt-4 flex flex-wrap items-center gap-4'>
        {!showGroupedItems
          ? seriesLegendItems.map((seriesLegend, index) => (
              <ChartLegendItem
                key={`${seriesLegend.name}-${index}`}
                color={seriesLegend.color}
                name={seriesLegend.name}
              />
            ))
          : Object.keys(groupedSeriesLegendItems).map(group => (
              <div
                key={group}
                className='border border-gray-200 px-2 py-1.5 rounded-md relative'
              >
                <div className='text-xs font-semibold bg-white px-1 absolute -top-2'>
                  Dataset: {group}
                </div>
                <div className='mt-2 flex flex-wrap items-center gap-x-3 gap-y-2 text-xxs font-medium'>
                  {groupedSeriesLegendItems[group].map(
                    (seriesLegend, index) => (
                      <ChartLegendItem
                        key={`${seriesLegend.name}-${index}`}
                        color={seriesLegend.color}
                        name={seriesLegend.metadata.subGroup ?? ''}
                      />
                    )
                  )}
                </div>
              </div>
            ))}
      </div>

      {seriesEmpty && <div className='text-gray-500'>No data</div>}
    </div>
  )
}
