import { useMutation } from '@apollo/client'
import { Box } from '@mui/system'
import { startCase } from 'lodash-es'
import { useState } from 'react'

import { Button, Select, notification } from '@/components'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { GET_USERS_IN_WORKSPACE } from '@/user/queries/admin'
import { UPDATE_USER_ROLE } from '@/user/queries/admin'

import { UserRoleValues } from '../constants'

export const UserRolePopover = ({
  row,
  onClose
}: {
  row: any
  onClose: () => void
}) => {
  const [selectedRole, setSelectedRole] = useState(row.role)
  const [api, contextHolder] = notification.useNotification()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const [updateUserRole, { loading: updatingUserRole }] =
    useMutation(UPDATE_USER_ROLE)

  const handleSave = async () => {
    const { data, errors } = await updateUserRole({
      variables: {
        organization_id: organization_id as string,
        workspace_ids: [workspace_id as string],
        user_id: row.id,
        role: selectedRole
      },
      refetchQueries: [GET_USERS_IN_WORKSPACE]
    })

    if (errors || !data) {
      api.error({
        message: 'Failed to update user role'
      })
    } else {
      api.success({
        message: 'User role updated successfully'
      })
    }

    onClose()
  }

  return (
    <Box width={350} className='flex flex-col gap-y-1 p-1'>
      {contextHolder}
      <div className='font-semibold'>Edit {row.user_name}</div>
      <div className='flex flex-col gap-y-1 mt-2'>
        <div className='text-xs text-gray-500'>Workspace Role</div>
        <Select
          options={Object.values(UserRoleValues).map(role => ({
            label: startCase(role),
            value: role
          }))}
          value={selectedRole}
          onChange={setSelectedRole}
        />
      </div>
      <div className='flex justify-end gap-x-2 mt-4'>
        <Button type='text' onClick={onClose}>
          Cancel
        </Button>
        <Button type='primary' onClick={handleSave} loading={updatingUserRole}>
          Save
        </Button>
      </div>
    </Box>
  )
}
