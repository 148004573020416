import { useQuery } from '@apollo/client'
import {
  Catalog,
  Home,
  ReportData,
  Upload,
  WatsonHealthCdCreateArchive
} from '@carbon/icons-react'
import { Box, styled } from '@mui/material'
import { find } from 'lodash-es'
import { useLocation, useNavigate, useParams } from 'react-router'

import { Workspace } from '@/gql_generated/graphql'

import { GET_ORGANIZATIONS } from '../../../user/queries/get_organizations'
import { NAV_FOREGROUND_COLOR } from '../Left_Nav.constants'
import { Left_Nav_List_Item } from './Left_Nav_List_Item'
import { Workspace_Switcher } from './Workspace_Switcher'

type Workspace_Nav_Items_Props = {
  is_left_nav_open: boolean
}

const NavItemsWrapper = styled('div')<Workspace_Nav_Items_Props>(
  ({ theme, is_left_nav_open }) => ({
    paddingInline: theme.spacing(is_left_nav_open ? 1 : 0.5),
    overflowX: 'hidden'
  })
)

export const Workspace_Nav_Items = ({
  is_left_nav_open
}: Workspace_Nav_Items_Props) => {
  const { workspace_key } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data, error, loading } = useQuery(GET_ORGANIZATIONS)

  const workspaces =
    data?.get_current_user_organizations.flatMap(
      organization => organization.workspaces
    ) ?? []
  const selected_workspace: Workspace | undefined = find(workspaces, {
    key: workspace_key
  })
  const organizations = data?.get_current_user_organizations ?? []

  if (error || loading || workspaces.length === 0) return null

  const { organization_id, key: selected_workspace_key } =
    selected_workspace || workspaces[0]
  const organization_key = find(organizations, { id: organization_id })?.key
  const path_prefix = `/${organization_key}/${selected_workspace_key}`

  return (
    <>
      <Box
        sx={{
          paddingInline: theme => theme.spacing(is_left_nav_open ? 2.5 : 0.5),
          paddingBottom: theme => theme.spacing(1)
        }}
      >
        <Workspace_Switcher is_left_nav_open={is_left_nav_open} />
      </Box>

      <NavItemsWrapper is_left_nav_open={is_left_nav_open}>
        <Left_Nav_List_Item
          selected={pathname.startsWith(`${path_prefix}/home`)}
          icon={<Home color={NAV_FOREGROUND_COLOR} size={24} />}
          onClick={() => navigate(`${path_prefix}/home`)}
          is_left_nav_open={is_left_nav_open}
          label='Playground'
        />

        <Left_Nav_List_Item
          selected={pathname.startsWith(`${path_prefix}/reports`)}
          icon={<ReportData color={NAV_FOREGROUND_COLOR} size={24} />}
          onClick={() => navigate(`${path_prefix}/reports`)}
          is_left_nav_open={is_left_nav_open}
          label='Reports'
        />

        <Left_Nav_List_Item
          selected={pathname.startsWith(`${path_prefix}/cell-specifications`)}
          icon={
            <WatsonHealthCdCreateArchive
              color={NAV_FOREGROUND_COLOR}
              size={24}
            />
          }
          onClick={() => navigate(`${path_prefix}/cell-specifications`)}
          is_left_nav_open={is_left_nav_open}
          label='Cell Specifications'
        />
        <Left_Nav_List_Item
          selected={pathname.startsWith(`${path_prefix}/upload-datasets`)}
          icon={<Upload color={NAV_FOREGROUND_COLOR} size={24} />}
          onClick={() => navigate(`${path_prefix}/upload-datasets`)}
          is_left_nav_open={is_left_nav_open}
          label='Upload Datasets'
        />
        <Left_Nav_List_Item
          selected={pathname.startsWith(`${path_prefix}/notebooks`)}
          icon={<Catalog color={NAV_FOREGROUND_COLOR} size={24} />}
          onClick={() => navigate(`${path_prefix}/notebooks`)}
          is_left_nav_open={is_left_nav_open}
          label='Notebooks'
        />

        {/* <Left_Nav_List_Item
          selected={pathname.startsWith(`${path_prefix}/alerts`)}
          icon={<Notification color={NAV_FOREGROUND_COLOR} />}
          onClick={() => navigate(`${path_prefix}/alerts`)}
          is_left_nav_open={is_left_nav_open}
          label='Alerts'
        /> */}
      </NavItemsWrapper>
    </>
  )
}
