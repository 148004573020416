import { useSearchParams } from 'react-router-dom'

/**
 * A hook to get a logger that can be toggled on and off with a query parameter.
 */
const useLogger = () => {
  const [searchParams] = useSearchParams()

  const debug = searchParams.get('debug') === 'true'
  const verbose = searchParams.get('verbose') === 'true'

  return {
    ...console,
    debug: debug ? console.log : () => {},
    verbose: verbose ? console.log : () => {}
  }
}

export default useLogger
