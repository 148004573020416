import { LicenseInfo } from '@mui/x-license'
import { createRoot } from 'react-dom/client'
import { H } from 'highlight.run'

import { Base } from './base/Base'
import './index.css'
import reportWebVitals from './reportWebVitals'

LicenseInfo.setLicenseKey(
  '49956b82edbdcdb9ff94f9e2395bab27Tz04MTA5OSxFPTE3MzQ5ODAyNzAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

if (process.env.NODE_ENV === 'production') {
  H.init('ng2kyyg1', { // Highlight project ID
    environment: 'production',
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [],
    },
    enableCanvasRecording: true,
    samplingStrategy: {
      canvas: 1 / 3,
      canvasMaxSnapshotDimension: 480,
    },
    manualStart: true
  })
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<Base />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
