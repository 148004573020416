import { useQuery } from '@apollo/client'
import { Box, Grid2, TextField } from '@mui/material'
import { useAtom } from 'jotai'
import { cloneDeep, isEmpty } from 'lodash-es'
import { FocusEvent, useMemo } from 'react'

import {
  Filter_Config,
  Normalize_Type,
  Property_Option
} from '@/gql_generated/graphql'
import { makeAxisTitle } from '@/insights/charts/transform'
import {
  AugmentedSelectorOption,
  NormalizeByOption,
  chartStatesFamily
} from '@/insights/jotai/charts.atoms'
import { use_workspace_and_org_ids } from '@/navigation/hooks/use_workspace_and_org_ids'
import { use_app_selector } from '@/state/redux/hooks'
import { dateValueToUnix, unixToDateValue } from '@/utils'

import { select_property_filter_keys } from '../charts/cycle_observations/cycler_observations_chart_slice'
import { GET_CYCLE_OBSERVATION_FILTER_CONFIGS } from '../charts/cycle_observations/queries/get_cycler_observation_filter_configs'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../charts/cycle_summaries/queries/get_cycle_summary_properties'
import { Absolute_Time_Byterat_Property } from '../charts/models/byterat_properties.model'
import { InsightsChartTypes } from '../charts/types'
import { format_property_label } from '../charts/utils'
import { select_selected_dataset_ids } from '../home/insights_slice'
import { PLOTTABLE_PROPERTY_TYPES } from '../types'
import { Autocomplete_Filter } from './filters/Autocomplete_Filter'
import DateFilter from './filters/DateFilter'
import Numeric_Filter from './filters/Numeric_Filter'
import { Menu_Header, Menu_Item_Control, use_menu_nav } from './menus'

export function InsightsPanelXAxis({ chartId }: { chartId: string }) {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  // Jotai chart state
  const [chartState, setChartState] = useAtom(
    chartStatesFamily({ id: chartId })
  )
  const {
    metricType,
    xAxisProperty: xProperty,
    xPropertyRange: xRange,
    normalizeByProperties,
    datasetIds
  } = chartState

  const additional_filter_keys = use_app_selector(select_property_filter_keys)
  const staged_dataset_table_row_ids = use_app_selector(
    select_selected_dataset_ids
  )

  const {
    data: cycleSummaryPropertiesData,
    loading: cycleSummaryPropertiesLoading
  } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id
  })

  const {
    data: observationFilterConfigsData,
    loading: observationFilterConfigsLoading
  } = useQuery(GET_CYCLE_OBSERVATION_FILTER_CONFIGS, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string],
      dataset_ids: !isEmpty(datasetIds)
        ? datasetIds
        : staged_dataset_table_row_ids,
      dataset_filters: [],
      additional_filter_keys
    },
    skip:
      !organization_id ||
      !workspace_id ||
      metricType !== InsightsChartTypes.IN_CYCLE_METRICS
  })

  const isAnythingLoading =
    cycleSummaryPropertiesLoading || observationFilterConfigsLoading

  // Compute the observation property options
  const observationPropertyOptions = useMemo(() => {
    let result: AugmentedSelectorOption[] = []

    switch (metricType) {
      case InsightsChartTypes.IN_CYCLE_METRICS:
        result =
          observationFilterConfigsData?.get_observation_filter_configs?.find(
            (property: Filter_Config) =>
              property.filter_property === 'plottable_observation_property'
          )?.options || []
        break
      case InsightsChartTypes.CYCLE_METRICS:
        result =
          cycleSummaryPropertiesData?.get_cycle_summary_properties?.cycle_summary_properties?.filter(
            (property: Property_Option) =>
              property.type && PLOTTABLE_PROPERTY_TYPES.includes(property.type)
          ) || []
        break
      case InsightsChartTypes.CELL_METRICS:
        result =
          cycleSummaryPropertiesData?.get_cycle_summary_properties?.dataset_properties?.filter(
            (property: Property_Option) =>
              property.type && PLOTTABLE_PROPERTY_TYPES.includes(property.type)
          ) || []
        break
      default:
        result = []
    }

    return result
      .map(v => ({
        ...v,
        label: format_property_label(v.label, v.units)
      }))
      .sort((a, b) => {
        if (a.metric_source === b.metric_source) {
          return a.label.localeCompare(b.label)
        }
        return a.metric_source?.localeCompare(b.metric_source ?? '') ?? 0
      })
  }, [cycleSummaryPropertiesData, observationFilterConfigsData, metricType])

  const normalizeByOptions = useMemo(() => {
    const datasetProperties: Property_Option[] =
      cycleSummaryPropertiesData?.get_cycle_summary_properties
        ?.dataset_properties || []

    return datasetProperties
      .filter(({ type }) => type && PLOTTABLE_PROPERTY_TYPES.includes(type))
      .map(({ key, label, units }: Property_Option) => ({
        key,
        label: format_property_label(label, units),
        units,
        type: Normalize_Type.DatasetProperty,
        group: 'Dataset Metrics'
      }))
  }, [cycleSummaryPropertiesData])

  return (
    <>
      <Menu_Header title='X-Axis' on_go_back={return_to_root} />

      <Box
        width={
          Absolute_Time_Byterat_Property.includes(xProperty?.key) ? 500 : 400
        }
        paddingBottom={2}
      >
        <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
          <Menu_Item_Control label='X-Axis' input_id='x-axis-type' emphasize>
            <Autocomplete_Filter
              id='x-axis-type'
              aria-labelledby='x-axis-type'
              disableClearable={true}
              grid_item_size={7}
              loading={isAnythingLoading}
              multiple={false}
              onChange={(_e, newXProperty) => {
                setChartState({
                  ...chartState,
                  xAxisProperty: {
                    ...newXProperty,
                    labelOverride: undefined
                  },
                  xPropertyRange: [null, null]
                })
              }}
              options={observationPropertyOptions}
              placeholder='Add Metric'
              value={{
                ...xProperty,
                label: format_property_label(xProperty.label, xProperty.units)
              }}
              groupBy={option => option.metric_source ?? ''}
            />
          </Menu_Item_Control>
          <Menu_Item_Control
            label='Normalize by'
            input_id='x-axis-normalize-by'
          >
            <Autocomplete_Filter
              id='x-axis-normalize-by'
              aria-labelledby='x-axis-normalize-by'
              disableClearable={false}
              grid_item_size={7}
              loading={
                cycleSummaryPropertiesLoading || observationFilterConfigsLoading
              }
              multiple={false}
              onChange={(_e, newNormalizeBy: Nullable<NormalizeByOption>) => {
                const _newNormalizeByProperties = cloneDeep(
                  normalizeByProperties
                )
                if (newNormalizeBy) {
                  _newNormalizeByProperties[xProperty.key] = newNormalizeBy
                } else {
                  delete _newNormalizeByProperties[xProperty.key]
                }

                setChartState({
                  ...chartState,
                  normalizeByProperties: _newNormalizeByProperties,
                  xAxisProperty: {
                    ...xProperty,
                    label: format_property_label(
                      xProperty.label,
                      xProperty.units
                    ),
                    labelOverride: undefined
                  }
                })
              }}
              options={normalizeByOptions}
              placeholder='Add Metric'
              // @ts-ignore
              value={normalizeByProperties[xProperty.key] ?? ''}
            />
          </Menu_Item_Control>

          <Menu_Item_Control label='Label Override' input_id='x-axis-label'>
            <Grid2 size={7}>
              <TextField
                className='text-xs w-full'
                id='x-axis-label'
                onFocus={event => {
                  if (event.target.value.trim().length === 0) {
                    event.target.value = makeAxisTitle(
                      xProperty,
                      normalizeByProperties
                    )
                  }
                  event.target.select()
                }}
                // On blur, if the value is the same as the default, clear it
                // because likely the user didn't change it, as it's the default
                onBlur={(event: FocusEvent<HTMLInputElement, Element>) => {
                  if (
                    event.target.value.trim() ===
                    makeAxisTitle(xProperty, normalizeByProperties)
                  ) {
                    event.target.value = ''
                  }
                }}
                onChange={(event: FocusEvent<HTMLInputElement, Element>) => {
                  let _newXProperty = cloneDeep(xProperty)
                  const newValue = event.target.value

                  if (newValue.trim().length > 0) {
                    _newXProperty.labelOverride = newValue
                  } else {
                    delete _newXProperty.labelOverride
                  }

                  setChartState({
                    ...chartState,
                    xAxisProperty: _newXProperty
                  })
                }}
                placeholder={makeAxisTitle(xProperty, normalizeByProperties)}
                value={xProperty.labelOverride ?? ''}
              />
            </Grid2>
          </Menu_Item_Control>
          <Menu_Item_Control
            label='Limits (Min - Max)'
            input_id='x-axis-limits'
          >
            <Grid2
              flexDirection='row'
              columnGap={1}
              display='inline-flex'
              size={7}
            >
              {Absolute_Time_Byterat_Property.includes(xProperty?.key) ? (
                <>
                  <DateFilter
                    placeholder='Min'
                    value={xRange?.[0] ? unixToDateValue(xRange?.[0]) : null}
                    onChange={date => {
                      setChartState({
                        ...chartState,
                        xPropertyRange: [
                          date ? dateValueToUnix(date) : null,
                          xRange?.[1]
                        ]
                      })
                    }}
                  />
                  <DateFilter
                    placeholder='Max'
                    value={xRange?.[1] ? unixToDateValue(xRange?.[1]) : null}
                    onChange={date => {
                      setChartState({
                        ...chartState,
                        xPropertyRange: [
                          xRange?.[0],
                          date ? dateValueToUnix(date) : null
                        ]
                      })
                    }}
                  />
                </>
              ) : (
                <>
                  <Numeric_Filter
                    placeholder='Min'
                    onBlur={event => {
                      const _parsedValue = Number(event.target.value)

                      setChartState({
                        ...chartState,
                        xPropertyRange: [
                          _parsedValue === 0
                            ? event.target.value === '0'
                              ? 0
                              : null
                            : _parsedValue,
                          xRange?.[1]
                        ]
                      })
                    }}
                    value={xRange?.[0] as number}
                  />

                  <Numeric_Filter
                    placeholder='Max'
                    onBlur={event => {
                      const _parsedValue = Number(event.target.value)

                      setChartState({
                        ...chartState,
                        xPropertyRange: [
                          xRange?.[0],
                          _parsedValue === 0
                            ? event.target.value === '0'
                              ? 0
                              : null
                            : _parsedValue
                        ]
                      })
                    }}
                    value={xRange?.[1] as number}
                  />
                </>
              )}
            </Grid2>
          </Menu_Item_Control>
        </Grid2>
      </Box>
    </>
  )
}
