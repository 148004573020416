export const COLUMN_HEADER_HEIGHT = 80
export const PAGE_SIZE_OPTIONS = [25, 50, 100]

export const UserRoleKeys = {
  UserName: 'user_name',
  Email: 'email_address',
  Role: 'role',
  CreatedAt: 'created_at',
  Id: 'id'
}

export const UserRoleValues = {
  Admin: 'admin',
  Member: 'member'
}

export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
