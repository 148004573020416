import { gql } from '../../gql_generated'

export const GET_USERS_IN_WORKSPACE = gql(`
  query get_users_in_workspace($workspace_ids: [String!]!, $page_size: Int!, $page_from: Int!) {
    get_users_in_workspace(workspace_ids: $workspace_ids, page_size: $page_size, page_from: $page_from) {
      data {
        id
        user_name
        email_address
        role
        created_at
        updated_at
      }
      total
      page
      page_size
    }
  }
`)

export const ADD_USER_TO_WORKSPACE = gql(`
  mutation add_user_to_workspace($organization_id: String!, $workspace_ids: [String!]!, $email: String!, $role: String!) {
    add_user_to_workspace(organization_id: $organization_id, workspace_ids: $workspace_ids, email: $email, role: $role) {
      existing_user
      existing_in_workspace
    }
  }
`)

export const CREATE_USER_AND_ADD_TO_WORKSPACE = gql(`
  mutation create_user_and_add_to_workspace($organization_id: String!, $workspace_ids: [String!]!, $create_user_input: Create_Propel_Auth_User_Input!) {
    create_user_and_add_to_workspace(organization_id: $organization_id, workspace_ids: $workspace_ids, create_user_input: $create_user_input) {
      id
      user_name
      email_address
      role
      created_at
      updated_at
    }
  }
`)

export const CREATE_USER = gql(`
  mutation create_user($create_user_input: Create_User_Input!) {
    create_user(create_user_input: $create_user_input) {
      id
      user_name
      email_address
      role
      created_at
      updated_at
    }
  }
`)

export const UPDATE_USER_ROLE = gql(`
  mutation update_user_role($organization_id: String!, $workspace_ids: [String!]!, $user_id: String!, $role: String!) {
    update_user_role(organization_id: $organization_id, workspace_ids: $workspace_ids, user_id: $user_id, role: $role)
  }
`)

export const REMOVE_USER_FROM_WORKSPACE = gql(`
  mutation remove_user_from_workspace($organization_id: String!, $workspace_ids: [String!]!, $user_id: String!) {
    remove_user_from_workspace(organization_id: $organization_id, workspace_ids: $workspace_ids, user_id: $user_id)
  }
`)
