import { useQuery } from '@apollo/client'
import { ChevronDown } from '@carbon/icons-react'
import {
  Box,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  alpha,
  styled
} from '@mui/material'
import { AutoTextSize } from 'auto-text-size'
import { find } from 'lodash-es'
import { MouseEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import { Workspace } from '../../../gql_generated/graphql'
import { GET_ORGANIZATIONS } from '../../../user/queries/get_organizations'
import { Nav_State } from '../Left_Nav'
import { NAV_FOREGROUND_COLOR } from '../Left_Nav.constants'

type Workspace_Switcher_Props = Nav_State

export function Workspace_Switcher(props: Workspace_Switcher_Props) {
  const { is_left_nav_open } = props

  const { workspace_key } = useParams()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { data, error, loading } = useQuery(GET_ORGANIZATIONS)

  const workspaces =
    data?.get_current_user_organizations.flatMap(
      organization => organization.workspaces
    ) ?? []
  const selected_workspace: Workspace | undefined = find(workspaces, {
    key: workspace_key
  })
  const organizations = data?.get_current_user_organizations ?? []

  const on_workspace_change = (
    organization_id: string,
    workspace_key: string
  ) => {
    const organization_key = find(organizations, { id: organization_id })?.key
    if (organization_key) {
    }
    navigate(`/${organization_key}/${workspace_key}`)
  }

  const open = anchorEl !== null

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onTriggerClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onItemClick = (index: number) => {
    const { organization_id, key } = workspaces[index]
    on_workspace_change(organization_id, key)
    handleClose()
  }

  if (error || workspaces.length === 0) return null

  const selected_workspace_name = selected_workspace?.name

  return (
    <>
      <Trigger_Wrapper>
        <Tooltip
          title={selected_workspace_name}
          placement='right'
          disableHoverListener={is_left_nav_open}
        >
          {loading ? (
            <Skeleton variant='rounded' animation='wave' width='100%' />
          ) : (
            <Trigger
              id='workspace-switcher'
              is_left_nav_open={is_left_nav_open}
              aria-controls={open ? 'workspace-switcher' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={onTriggerClick}
            >
              <Trigger_Text
                mode='oneline'
                maxFontSizePx={14}
                minFontSizePx={10}
              >
                {selected_workspace_name}
              </Trigger_Text>
              {is_left_nav_open && <Styled_ChevronDown />}
            </Trigger>
          )}
        </Tooltip>
      </Trigger_Wrapper>
      <Menu
        id='lock-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: anchorEl?.offsetWidth
            }
          }
        }}
      >
        {workspaces.map((option, index) => (
          <MenuItem
            key={option.id}
            selected={option.key === selected_workspace?.key}
            onClick={() => onItemClick(index)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const Trigger_Wrapper = styled(Box)`
  display: flex;
  overflow: hidden;
`

const Trigger = styled('button')(({ is_left_nav_open }: Nav_State) => ({
  alignItems: 'center!important',
  border: 1,
  borderStyle: 'solid',
  borderColor: alpha(NAV_FOREGROUND_COLOR, 1 / 3),
  color: NAV_FOREGROUND_COLOR,
  flex: 1,
  justifyContent: is_left_nav_open ? 'space-between' : 'center',
  height: 34,

  // MUI button styles
  display: 'flex',
  padding: '6px',
  borderRadius: '8px',
  lineHeight: 1.75,
  fontWeight: 500,
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  textTransform: 'uppercase',
  minWidth: 64,
  backgroundColor: 'transparent',
  cursor: 'pointer',
  outline: 'none',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    backgroundColor: alpha(NAV_FOREGROUND_COLOR, 1 / 3)
  },
  '&:focus-visible': {
    outline: `2px solid ${NAV_FOREGROUND_COLOR}`,
    outlineOffset: 2
  }
}))

const Trigger_Text = styled(AutoTextSize)`
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 500;
`

const Styled_ChevronDown = styled(ChevronDown)`
  color: ${NAV_FOREGROUND_COLOR};
  min-width: 1.125rem;
  width: 1.125rem;
`
