import dayjs from 'dayjs'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { cloneDeep, uniq } from 'lodash-es'
import numbro from 'numbro'

import { DateFormats } from '@/constants'

import {
  AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD,
  CHART_COLORS,
  CHART_DETAIL_SIG_FIG,
  CHART_TYPE,
  DEFAULT_FILL_MARKER_SIZE,
  DEFAULT_MARKER_STROKE_WIDTH,
  DEFAULT_OPACITY,
  DEFAULT_STROKE_WIDTH,
  VALID_SCATTER_SHAPES
} from '../charts'
import {
  Absolute_Time_Byterat_Property,
  Non_Absolute_Time_Byterat_Property
} from '../charts/models/byterat_properties.model'
import { makeAxisTitle } from '../charts/transform'
import { InsightsChartTypes } from '../charts/types'
import { DataPointSelectionOpts } from '../types'
import {
  ApexChartSeries,
  ApexChartSeriesLegendItem,
  _getAnnotations,
  _getAxisLabelFormatting,
  _getAxisLimitValue,
  _getCellMetricsSeries,
  _getObservationsSeries,
  _getSummarySeries
} from './apexHelpers'
import { getCustomTooltipHtml } from './apexHelpers/_getCustomTooltipHtml'
import { chartSelectedAnnotationsAtom, chartStatesFamily } from './charts.atoms'

const CATEGORICAL_PROPERTIES = ['dataset_cycle', 'cycle_number']

// Derived atom for chart options (replaces select_apex_chart_options)
export const apexChartOptionsAtom = atomFamily((id: string) =>
  atom(null, (get, set) => {
    const chartState = get(chartStatesFamily({ id })) // Full chart state
    const {
      aggregateByProperty,
      chartType,
      datasetColumns,
      datasetIds,
      datasets,
      groupByProperty,
      metricTargetLimits,
      metricTargetsApplied,
      metricType,
      normalizeByProperties,
      rawData,
      xAxisProperty,
      xPropertyRange,
      yAxisProperties,
      yPropertyRanges
    } = chartState

    const xAxisIsNormalized =
      normalizeByProperties?.[xAxisProperty.key]?.key != null

    // Configure various aspects of the chart based on the metric
    let colors: string[] = cloneDeep(CHART_COLORS)
    let computedY1Min: number | undefined = undefined
    let computedY1Max: number | undefined = undefined
    let dashArray: number[] = []
    let series: ApexChartSeries[] = []
    let markerShapes: string[] = []
    let markerSizes: number[] = []
    let yPropertySeriesNames: { [yPropertyIndex: number]: string[] } = {}
    let seriesLegendItems: ApexChartSeriesLegendItem[] = []
    let strokeWidths: number | number[] = []
    let opacity: number | number[] = []

    switch (metricType) {
      case InsightsChartTypes.CYCLE_METRICS: {
        const seriesInfo = _getSummarySeries({
          rawData,
          xAxisProperty,
          yAxisProperties,
          groupByProperty,
          aggregateByProperty,
          chartType
        })

        colors = seriesInfo.colors
        dashArray = seriesInfo.dashArray
        series = seriesInfo.series
        seriesLegendItems = seriesInfo.seriesLegendItems
        yPropertySeriesNames = seriesInfo.yPropertySeriesNames
        strokeWidths = seriesInfo.strokeWidths
        opacity = seriesInfo.opacity
        break
      }
      case InsightsChartTypes.IN_CYCLE_METRICS: {
        const seriesInfo = _getObservationsSeries({
          rawData,
          xAxisProperty,
          yAxisProperties,
          groupByProperty
        })

        colors = seriesInfo.colors
        dashArray = seriesInfo.dashArray
        series = seriesInfo.series
        seriesLegendItems = seriesInfo.seriesLegendItems
        yPropertySeriesNames = seriesInfo.yPropertySeriesNames
        strokeWidths = DEFAULT_STROKE_WIDTH(chartType) // Update if shaded region use case is added
        opacity = DEFAULT_OPACITY // Update if shaded region use case is added
        break
      }
      case InsightsChartTypes.CELL_METRICS: {
        const seriesInfo = _getCellMetricsSeries({
          datasets,
          datasetColumns,
          datasetIds,
          groupByProperty,
          xAxisProperty,
          yAxisProperties,
          normalizeByProperties
        })

        colors = seriesInfo.colors
        computedY1Min = seriesInfo.computedY1Min
        computedY1Max = seriesInfo.computedY1Max
        markerShapes = seriesInfo.markerShapes
        markerSizes = seriesInfo.markerSizes
        series = seriesInfo.series
        seriesLegendItems = seriesInfo.seriesLegendItems
        strokeWidths = DEFAULT_STROKE_WIDTH(chartType) // Update if shaded region use case is added
        opacity = DEFAULT_OPACITY // Update if shaded region use case is added
        break
      }
    }

    /*
     * TARGET METRIC annotations
     */
    const annotations: ApexAnnotations = {
      yaxis: []
    }
    if (metricType === InsightsChartTypes.CELL_METRICS && metricTargetLimits) {
      const _matchingLimit = metricTargetLimits.find(
        ({ metric_name }) => yAxisProperties?.[0]?.key === metric_name
      )

      if (_matchingLimit) {
        const paddingPercentage = 0.1 // Set your desired padding percentage
        const computedRange =
          Math.max(computedY1Max || 0, _matchingLimit.upper_limit) -
          Math.min(computedY1Min || 0, _matchingLimit.lower_limit)

        const _limitsToApply = []
        if (metricTargetsApplied.includes('upper_limit')) {
          _limitsToApply.push(_matchingLimit.upper_limit)
        }
        if (metricTargetsApplied.includes('lower_limit')) {
          _limitsToApply.push(_matchingLimit.lower_limit)
        }
        _limitsToApply.forEach(limit => {
          if (limit !== undefined) {
            annotations.yaxis?.push({
              y: limit,
              strokeDashArray: 5,
              borderColor: 'black'
            })

            // Set computedY1Min with padding if it's undefined or limit is less than it
            if (computedY1Min === undefined || limit <= computedY1Min) {
              computedY1Min = parseFloat(
                (
                  limit -
                  (computedRange * paddingPercentage || Math.round(limit / 20))
                ).toFixed(1)
              )
            }

            // Set computedY1Max with padding if it's undefined or limit is greater than it
            if (computedY1Max === undefined || limit >= computedY1Max) {
              computedY1Max = parseFloat(
                (
                  limit +
                  (computedRange * paddingPercentage || Math.round(limit / 20))
                ).toFixed(1)
              )
            }
          }
        })
      }
    }

    // Configure X-axis options
    const xAxisType = Absolute_Time_Byterat_Property.includes(xAxisProperty.key)
      ? 'datetime'
      : CATEGORICAL_PROPERTIES.includes(xAxisProperty.key) && !xAxisIsNormalized
        ? 'category'
        : 'numeric'

    const xValues = uniq(series.flatMap(s => s.data.map(d => d.x)))
    const tickAmount = xValues.length - 1

    const apexXAxis: ApexXAxis = {
      title: {
        text:
          xAxisProperty.labelOverride ??
          makeAxisTitle(xAxisProperty, normalizeByProperties)
      },
      min:
        typeof xPropertyRange[0] === 'number'
          ? _getAxisLimitValue(xAxisProperty, xPropertyRange[0])
          : undefined,
      max:
        typeof xPropertyRange[1] === 'number'
          ? _getAxisLimitValue(xAxisProperty, xPropertyRange[1])
          : undefined,
      // @ts-ignore
      labels: {
        ..._getAxisLabelFormatting(xAxisProperty.key, xAxisType)
      },
      type: xAxisType,
      hideOverlappingLabels: true,
      tickAmount:
        metricType === InsightsChartTypes.CELL_METRICS
          ? 'dataPoints'
          : Math.min(tickAmount, 10)
    }

    // Configure Y-axis options for each Y property
    const apexYAxes = yAxisProperties
      .filter(({ key }) => !!key)
      .map((yProperty, index) => {
        const _markerShape = [
          InsightsChartTypes.CYCLE_METRICS,
          InsightsChartTypes.CELL_METRICS
        ].includes(metricType)
          ? VALID_SCATTER_SHAPES[index % VALID_SCATTER_SHAPES.length]
          : undefined

        let min =
          typeof yPropertyRanges?.[index]?.[0] === 'number'
            ? _getAxisLimitValue(yProperty, yPropertyRanges?.[index]?.[0])
            : undefined
        if (index === 0 && min === undefined && computedY1Min !== undefined) {
          min = computedY1Min
        }

        let max =
          typeof yPropertyRanges?.[index]?.[1] === 'number'
            ? _getAxisLimitValue(yProperty, yPropertyRanges?.[index]?.[1])
            : undefined
        if (index === 0 && max === undefined && computedY1Max !== undefined) {
          max = computedY1Max
        }

        return {
          title: {
            text:
              yProperty.labelOverride ??
              makeAxisTitle(yProperty, normalizeByProperties)
          },
          labels: {
            ..._getAxisLabelFormatting(yProperty.key)
          },
          min,
          max,
          opposite: index !== 0,
          marker: _markerShape,
          dashArray: index !== 0 ? 5 : 0,
          seriesName: yPropertySeriesNames[index],
          forceNiceScale: true
        }
      })

    const configChartType =
      metricType === InsightsChartTypes.CYCLE_METRICS &&
      groupByProperty?.key !== null &&
      aggregateByProperty?.key === AGGREGATION_TYPE_MEAN_PLUS_MINUS_STD.key
        ? 'rangeArea'
        : (chartType as ApexChart['type'])

    const result = {
      options: {
        colors,
        stroke: {
          width: strokeWidths,
          curve: 'straight',
          dashArray
        },
        markers: {
          size: [CHART_TYPE.LINE_MARKER, CHART_TYPE.SCATTER].includes(chartType)
            ? markerSizes?.length
              ? markerSizes
              : DEFAULT_FILL_MARKER_SIZE
            : 0,
          strokeWidth: DEFAULT_MARKER_STROKE_WIDTH,
          strokeColors: colors,
          shape: markerShapes?.length ? markerShapes : 'circle',
          radius: 0,
          hover: {
            sizeOffset: 5
          }
        },
        fill: {
          opacity
        },
        legend: {
          show: false
        },
        chart: {
          id,
          selection: {
            enabled: true,
            type: 'xy'
          },
          events: {
            dataPointSelection: (
              _event: MouseEvent,
              _chartContext: any,
              opts: DataPointSelectionOpts
            ) => {
              // Update selected annotations atom
              const annotations = _getAnnotations(
                opts,
                series,
                xAxisProperty,
                yAxisProperties
              )
              set(chartSelectedAnnotationsAtom, annotations)
            }
          },
          type: configChartType,
          zoom: {
            type: 'xy',
            enabled: true
          },
          toolbar: {
            show: false,
            export: {
              csv: {
                headerCategory:
                  xAxisProperty.labelOverride ??
                  makeAxisTitle(xAxisProperty, normalizeByProperties)
              }
            }
          },
          animations: {
            enabled: false
          }
        },
        states: {
          active: {
            allowMultipleDataPointsSelection: true
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          shared:
            metricType !== InsightsChartTypes.CELL_METRICS &&
            chartType === CHART_TYPE.LINE,
          intersect:
            metricType === InsightsChartTypes.CELL_METRICS ||
            chartType !== CHART_TYPE.LINE,
          marker: {
            show: chartType === CHART_TYPE.LINE
          },
          x: {
            formatter: function (val: number, otherProps: any) {
              const { w } = otherProps
              if (w == null) return val
              const xAxisTitle = w.config.xaxis.title.text
              let formattedVal

              if (
                Non_Absolute_Time_Byterat_Property.includes(xAxisProperty.key)
              ) {
                formattedVal = numbro(dayjs.duration(val).asHours()).format({
                  thousandSeparated: true,
                  mantissa: CHART_DETAIL_SIG_FIG,
                  trimMantissa: true
                })
              } else if (
                Absolute_Time_Byterat_Property.includes(xAxisProperty.key)
              ) {
                formattedVal = dayjs
                  .utc(val)
                  .format(
                    xAxisIsNormalized
                      ? DateFormats.TIME
                      : DateFormats.CHART_DATE_TIME
                  )
              } else if (xAxisType !== 'category') {
                formattedVal = numbro(val).format({
                  thousandSeparated: true,
                  mantissa: CHART_DETAIL_SIG_FIG,
                  trimMantissa: true
                })
              } else {
                formattedVal = val
              }

              return `${xAxisTitle}: <b>${formattedVal}</b>`
            }
          },
          y: {
            formatter: function (val: number) {
              return numbro(val).format({
                thousandSeparated: true,
                mantissa: CHART_DETAIL_SIG_FIG,
                trimMantissa: true
              })
            },
            title: {
              formatter: function (seriesName: string) {
                return seriesName
              }
            }
          },
          custom: function ({
            seriesIndex,
            dataPointIndex,
            w
          }: {
            seriesIndex: number
            dataPointIndex: number
            w: any
          }) {
            const seriesType = series[seriesIndex].type

            const annotations: string[] =
              series[seriesIndex].data[dataPointIndex].annotations

            const xValue = w.globals.seriesX[seriesIndex][dataPointIndex]
            const yValue = series[seriesIndex].data[dataPointIndex].y

            const seriesName = w.globals.seriesNames[seriesIndex]

            // Use existing X-axis formatting
            const xFormatter = w.config.tooltip.x.formatter
            const xFormatted = xFormatter ? xFormatter(xValue, { w }) : xValue

            // Use existing Y-axis formatting
            const yFormatter = w.config.tooltip.y.formatter

            let yFormatted
            if (seriesType === 'rangeArea' && typeof yValue === 'object') {
              // For range tooltips, format the value as [lower, upper]
              yFormatted = yFormatter
                ? `[${yFormatter(yValue[0])}, ${yFormatter(yValue[1])}]`
                : `[${yValue[0]}, ${yValue[1]}]`
            } else {
              // For non-range tooltips, format the value as a single number
              yFormatted = yFormatter ? yFormatter(yValue) : yValue
            }

            const yTitleFormatter = w.config.tooltip.y.title.formatter
            const yTitle = yTitleFormatter
              ? yTitleFormatter(seriesName)
              : seriesName

            return getCustomTooltipHtml(
              xFormatted,
              yTitle,
              yFormatted,
              w.globals.colors[seriesIndex],
              annotations
            )
          }
        },
        xaxis: apexXAxis,
        yaxis: apexYAxes,
        annotations,
        series,
        seriesLegendItems
      },
      type: configChartType
    }

    return result
  })
)
