import {
  RedirectToLogin,
  RequiredAuthProvider,
  useAuthInfo
} from '@propelauth/react'
import { H } from 'highlight.run'
import { ReactNode, useEffect } from 'react'

import { use_app_dispatch } from '../state/redux/hooks'
import {
  set_access_token,
  set_email,
  set_propelauth_id
} from '../user/user_slice'

const byterat_propel_auth_url =
  process.env.REACT_APP_byterat_propel_auth_url || ''

export const Auth_Provider = ({ children }: { children: ReactNode }) => {
  return (
    <RequiredAuthProvider
      authUrl={byterat_propel_auth_url}
      displayIfLoggedOut={
        <RedirectToLogin postLoginRedirectUrl={`${window.location.href}`} />
      }
    >
      <Propel_Auth_To_User_Slice>{children}</Propel_Auth_To_User_Slice>
    </RequiredAuthProvider>
  )
}

const Propel_Auth_To_User_Slice = ({ children }: { children: ReactNode }) => {
  const dispatch = use_app_dispatch()
  const auth_info = useAuthInfo()

  if (
    process.env.NODE_ENV === 'production' &&
    auth_info.user?.email &&
    !auth_info.user.email.endsWith('byterat.io')
  ) {
    H.identify(auth_info.user.email, {})
    H.start()
  }

  useEffect(() => {
    dispatch(set_access_token(auth_info.accessToken))
    dispatch(set_email(auth_info.user?.email))
    dispatch(set_propelauth_id(auth_info.user?.userId))
  }, [auth_info, dispatch])

  return children
}
